import React, { useState } from "react";
import { UserGroupIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import { toast } from "sonner";

const CreateClient = ({ widgetId }) => {
  const [reservationData, setReservationData] = useState({
    fullName: "",
    tel: "",
    email: "",
  });

  const handleChange = (e) => {
    setReservationData({
      ...reservationData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !reservationData.fullName ||
      !reservationData.tel ||
      !reservationData.email
    ) {
      toast.error("Veuillez remplir tous les champs");
      return;
    }

    try {
      const createClient = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/widgets/taxi/client/widget/${widgetId}`,
        reservationData
      );
      if (createClient.status === 201) {
        toast.success("Fiche client créée avec succès");
        setReservationData({
          fullName: "",
          tel: "",
          email: "",
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        toast.error("Cette fiche client existe déjà");
      } else {
        toast.error("Erreur lors de la création de la fiche client");
      }
    }
  };

  return (
    <div className="mx-auto">
      <div className="mx-auto text-center">
        <p className="font-display text-3xl tracking-tight text-slate-800 sm:text-4xl">
          Créer une fiche client
        </p>
      </div>

      <div className="flex justify-between gap-4 mt-4">
        <div className="w-1/2 pr-4 rounded-2xl border border-gray-200 p-8">
          <h3 className="font-bold text-lg text-gray-900 flex items-center">
            <span className="mr-3">
              <UserGroupIcon className="h-6 w-6" />
            </span>
            Fiche client
          </h3>
          <form onSubmit={handleSubmit}>
            <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-3">
              <label htmlFor="fullName">Nom complet :</label>
              <input
                type="text"
                name="fullName"
                className="p-1 border px-6 border-gray-200 rounded-xl w-[330px]"
                value={reservationData.fullName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-3">
              <label htmlFor="tel">Téléphone :</label>
              <input
                type="tel"
                name="tel"
                className="p-1 border px-6 border-gray-200 rounded-xl w-[330px]"
                value={reservationData.tel}
                onChange={handleChange}
                required
              />
            </div>
            <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-3">
              <label htmlFor="email">Email :</label>
              <input
                type="email"
                name="email"
                className="p-1 border px-6 border-gray-200 rounded-xl w-[330px]"
                value={reservationData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mt-6">
              <button
                type="submit"
                className="w-full bg-[#00073d] text-white rounded-md p-2"
              >
                Créer
              </button>
            </div>
          </form>
        </div>
        <div className="w-1/2 pl-4 rounded-2xl border border-gray-200 p-8">
          <div className="max-w-2xl mx-4 sm:max-w-sm md:max-w-sm lg:max-w-sm xl:max-w-sm sm:mx-auto md:mx-auto lg:mx-auto xl:mx-auto mt-6 bg-white shadow-xl rounded-lg text-gray-900">
            <div className="rounded-t-lg h-32 overflow-hidden">
              <img
                className="object-cover object-top w-full"
                src="https://images.unsplash.com/photo-1518623001395-125242310d0c?q=80&w=2549&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="Mountain"
              />
            </div>
            <div className="mx-auto w-32 h-32 relative -mt-16 border-4 border-white rounded-full overflow-hidden">
              <img
                className="object-cover object-center h-32"
                src="https://cdn-icons-png.flaticon.com/512/6596/6596121.png"
                alt="Woman looking front"
              />
            </div>
            <div className="text-center mt-2">
              <h2 className="font-semibold">{reservationData?.fullName}</h2>
              <p className="text-gray-500">{reservationData?.email}</p>
            </div>

            <div className="p-4 border-t mx-8 mt-2">
              <a href={`tel: ${reservationData?.tel}`}></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateClient;
