import React, { useCallback, useEffect, useState } from "react";
import useFetch from "../../../Component/Hooks/useFetch";
import axios from "axios";
import Modal from "../../../Component/Modal/Modal";
import CreateReservations from "./CreateReservations";
import Reservation from "./Reservation";
import Loader from "../Loader/Loader";

const ListReservations = ({ userId }) => {
  const [widgets, setWidgets] = useState([]);
  const [selectedWidgetId, setSelectedWidgetId] = useState("");
  const [reservationDetails, setReservationDetails] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const { data, loading } = useFetch(
    `${process.env.REACT_APP_API_URL}/api/widgets/taxi/getWidgets/${userId}`
  );

  const handleWidgetChange = useCallback((event) => {
    setSelectedWidgetId(event.target.value);
  }, []);

  const handleCreateReservation = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const loadReservationDetails = useCallback(async () => {
    if (selectedWidgetId) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/widgets/taxi/reservations/${selectedWidgetId}`
        );

        setReservationDetails(response?.data);
      } catch (error) {
        console.error("Error loading reservation details:", error);
      }
    }
  }, [selectedWidgetId]);

  useEffect(() => {
    if (data) {
      setWidgets(data);
      setSelectedWidgetId(data?.widgets[0]?._id);
    }
  }, [data, userId]);

  useEffect(() => {
    if (selectedWidgetId) {
      loadReservationDetails();
    }
  }, [loadReservationDetails, selectedWidgetId]);

  return (
    <div className="flex-col p-2 lg:p-4 w-full">
      <div className="flex gap-4 justify-between">
        <h1 className="title_local">Mes réservations</h1>
        {widgets?.widgets && (
          <select
            onChange={handleWidgetChange}
            value={selectedWidgetId}
            className="border border-gray-200 rounded-md px-2 py-1 min-w-[200px] sm:w-auto max-h-[40px] text-gray-700 transition-all shadow-sm hover:shadow-sm hover:shadow-indigo-400 hover:-translate-y-px"
          >
            {widgets?.widgets?.map((widget) => (
              <option key={widget._id} value={widget._id}>
                {widget?.title}
              </option>
            ))}
          </select>
        )}
        <button
          onClick={handleCreateReservation}
          className="inline-block w-auto min-w-[250px] max-h-[40px] py-1 text-white transition-all rounded-md shadow-xl sm:w-auto bg-[#00073d] shadow-indigo-200 hover:shadow-2xl hover:shadow-indigo-400 hover:-translate-y-px "
        >
          Créer une réservation
        </button>
      </div>

      {/* Liste des réservations */}
      <div className="border p-2 lg:p-4 rounded-xl flex space-x-0 space-y-4  w-full">
        <div className="mr-6 mt-3"></div>
        <div style={{ flex: "2" }}>
          {/* Affichage des détails de réservation */}

          {reservationDetails ? (
            <Reservation
              reservation={reservationDetails}
              widgetId={selectedWidgetId}
              loadReservationDetails={loadReservationDetails}
            />
          ) : (
            loading && <Loader />
          )}
        </div>
      </div>
      {/* Modal de création de réservation */}
      {showModal && (
        <Modal
          onClose={handleCloseModal}
          isOpen={showModal}
          children={
            <CreateReservations
              widgetId={selectedWidgetId}
              onClose={handleCloseModal}
              loadReservationDetails={loadReservationDetails}
            />
          }
        />
      )}
    </div>
  );
};

export default ListReservations;
