import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Toaster } from "sonner";
import "./Style/index.css";
import { ThemeProvider } from "@material-tailwind/react";
import { composeWithDevTools } from "@redux-devtools/extension";
import { Provider, useDispatch } from "react-redux";
import rootReducer from "./reducers/index.js";
import { legacy_createStore as createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import "remixicon/fonts/remixicon.css";
import InternetConnectionStatus from "./Component/Log/InternetConnectionStatus.js";
import CheckSession from "./Component/Log/Checksession.js";
import { getUser } from "./actions/user.actions";
import { getPartenaire } from "./actions/partenaire.actions.js";
import axios from "axios";

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

const FetchUserData = () => {
  const [uid, setUid] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/user/token/jwt`,
          { withCredentials: true }
        );
        setUid(res.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchUser();
  }, []);

  useEffect(() => {
    if (uid?.role === "partenaire") {
      dispatch(getPartenaire());
    } else if (uid?.role === "admin") {
      dispatch(getUser(uid?.userId));
    }
  }, [dispatch, uid]);

  return null;
};

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <Toaster position="bottom-left" expand={false} richColors closeButton />
    <CheckSession />
    <InternetConnectionStatus />
    <FetchUserData />
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </Provider>
);
