import React, { useCallback, useEffect, useState } from "react";
import useFetch from "../../../Component/Hooks/useFetch";
import PaiementList from "./PaiementList";

const MesPaiements = ({ userId }) => {
  const [widgets, setWidgets] = useState([]);
  const [selectedWidgetId, setSelectedWidgetId] = useState("");

  const { data } = useFetch(
    `${process.env.REACT_APP_API_URL}/api/widgets/taxi/getWidgets/${userId}`
  );

  const handleWidgetChange = useCallback((event) => {
    setSelectedWidgetId(event.target.value);
  }, []);

  useEffect(() => {
    if (data) {
      setWidgets(data);
      setSelectedWidgetId(data?.widgets[0]?._id);
    }
  }, [data, userId]);

  return (
    <div className="flex-col p-2 lg:p-4 w-full">
      <div className="flex gap-4 justify-between">
        <h1 className="title_local">Mes paiements</h1>
        {widgets?.widgets && (
          <select
            onChange={handleWidgetChange}
            value={selectedWidgetId}
            className="border border-gray-200 rounded-md px-2 py-1 min-w-[200px] sm:w-auto max-h-[40px] text-gray-700 transition-all shadow-sm hover:shadow-sm hover:shadow-indigo-400 hover:-translate-y-px"
          >
            {widgets?.widgets?.map((widget) => (
              <option key={widget._id} value={widget._id}>
                {widget?.title}
              </option>
            ))}
          </select>
        )}
      </div>

      {/* Liste des réservations */}
      <div className="border p-2 lg:p-4 rounded-xl flex space-x-0 space-y-4  w-full">
        <div className="mr-6 mt-3"></div>
        <div style={{ flex: "2" }}>
          {/* Affichage des détails des paiements */}
          {selectedWidgetId && (
            <PaiementList
              selectedWidgetId={selectedWidgetId}
              widgets={widgets}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MesPaiements;
