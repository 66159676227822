import React, { useEffect, useState } from "react";
import {
  ArrowDownTrayIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import {
  Card,
  CardHeader,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Avatar,
  IconButton,
  Tooltip,
  Input,
} from "@material-tailwind/react";
import Loader from "../Loader/Loader";
import useFetch from "../../../Component/Hooks/useFetch";
import moment from "moment";
import axios from "axios";
import { toast } from "sonner";
import jsPDF from "jspdf";
import "jspdf-autotable";

const TABLE_HEAD = [
  "Client",
  "Montant",
  "Date",
  "Statut",
  "Mode de paiement",
  "Facture",
];

const PaiementList = ({ selectedWidgetId, widgets }) => {
  const [clients, setClients] = useState([]);

  const widget = widgets?.widgets?.find(
    (widget) => widget._id === selectedWidgetId
  );

  const { data, loading } = useFetch(
    `${process.env.REACT_APP_API_URL}/api/widgets/taxi/client/widget/${selectedWidgetId}/client/`
  );

  useEffect(() => {
    if (widget) {
      setClients(data?.reservations);
    }
  }, [data]);

  const imageCash =
    "https://cdn.pixabay.com/animation/2023/06/13/15/12/15-12-37-87_512.gif";
  const imageCard =
    "https://cdn.pixabay.com/animation/2023/06/13/15/13/15-13-37-55_512.gif";
  const profil =
    "https://cdn.pixabay.com/animation/2023/06/13/15/13/15-13-36-234_512.gif";

  const handleValidatePaiement = (reservationId, client) => {
    try {
      const confirmation = window.confirm(
        "Êtes-vous sûr de vouloir valider le paiement ? Cette action va générer une facture."
      );
      if (confirmation) {
        axios.post(
          `${process.env.REACT_APP_API_URL}/api/widgets/taxi/reservations/widget/${selectedWidgetId}/validate/${reservationId}`
        );

        setClients((prev) =>
          prev.map((client) => {
            if (client.reservationId === reservationId) {
              return { ...client, isPaid: true };
            }
            return client;
          })
        );
        downloadInvoicePDF(client);
        toast.success("La facture est désormais validée et disponible.");
      } else {
        toast.error("La facture n'a pas été validée.");
      }
    } catch (error) {
      console.error(error);
      toast.error("Impossible de valider le paiement.");
    }
  };

  const downloadInvoicePDF = (client) => {
    // Créer une nouvelle instance de jsPDF
    const pdf = new jsPDF();

    const { contactPhone, contactMail } = widget;

    // Informations de l'entreprise en haut à droite
    const enterpriseText = `Entreprise :\n\n${contactPhone}\n${contactMail}\n`;

    // Définir les coordonnées pour le cadre et le texte de l'entreprise
    const enterpriseTextX = pdf.internal.pageSize.width - 100;
    const enterpriseTextY = 30;
    const enterpriseTextWidth = 80;
    const enterpriseTextHeight = 40;

    // Dessiner le cadre autour des informations de l'entreprise
    pdf.rect(
      enterpriseTextX,
      enterpriseTextY,
      enterpriseTextWidth,
      enterpriseTextHeight
    );

    // Placer le texte de l'entreprise dans le cadre
    pdf.text(enterpriseText, enterpriseTextX + 5, enterpriseTextY + 10);

    // Informations du client dans un cadre en bas à gauche
    const clientText = `Client: ${client.fullName}\n\nTél: ${
      client.tel
    }\nEmail: ${client.email},\nNumero de réservation: ${
      client.reservationId
    },\nMode de paiement: ${
      client.paiementMethod === "cash" ? "Paiement au chauffeur" : "💳 Stripe"
    }`;

    // Définir les coordonnées pour le cadre et le texte du client
    const clientTextX = 20;
    const clientTextY = 80;
    const clientTextWidth = 180;
    const clientTextHeight = 60;

    // Dessiner le cadre autour des informations du client
    pdf.rect(clientTextX, clientTextY, clientTextWidth, clientTextHeight);

    // Placer le texte du client dans le cadre
    pdf.text(clientText, clientTextX + 5, clientTextY + 10);

    // Informations du trajet par ligne
    const tripHeaders = ["Date", "Départ", "Arrivée", "Prix"];
    const tripRows = [
      [
        moment(client.createdAt).format("DD/MM/YYYY"),
        moment(client.departureTime).format("HH:mm"),
        moment(client.arrivedTime).format("HH:mm"),
        `${client.price?.$numberDecimal} € TTC`,
      ],
    ];

    pdf.autoTable({
      startY: 150, // Remonter la partie détails de la course
      head: [tripHeaders],
      body: tripRows,
      theme: "striped",
    });

    // Montant total en bas à droite
    pdf.text(
      `Montant Total: ${client.price?.$numberDecimal} €`,
      pdf.internal.pageSize.width - 80,
      pdf.internal.pageSize.height - 20,
      null,
      null,
      "right"
    );

    // Télécharger le PDF
    pdf.save("facture.pdf");
  };

  console.log(widget);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Card className="h-full w-full">
          <CardHeader floated={false} shadow={false} className="rounded-none">
            <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
              <div>
                <Typography variant="h5" color="blue-gray">
                  Transactions récentes
                </Typography>
                <Typography color="gray" className="mt-1 font-normal">
                  Liste des transactions récentes effectuées par vos clients.
                </Typography>
              </div>
              <div className="flex w-full shrink-0 gap-2 md:w-max">
                <div className="w-full md:w-72">
                  <Input
                    label="Rechercher une transaction"
                    icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                  />
                </div>
                <Button className="flex items-center gap-3" size="sm">
                  <ArrowDownTrayIcon
                    strokeWidth={2}
                    className="h-4 w-4 custom-white-icon"
                  />
                  Télécharger
                </Button>
              </div>
            </div>
          </CardHeader>
          <CardBody className="overflow-scroll px-0">
            <table className="w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th
                      key={head}
                      className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {clients?.map(
                  (
                    {
                      paiementMethod,
                      fullName,
                      price,
                      isPaid,
                      reservationId,
                      createdAt,
                      baggage,
                      departureTime,
                      arrivedTime,
                      email,
                      tel,
                      passengers,
                      km,
                      isRoundTrip,
                    },
                    index
                  ) => {
                    const isLast = index === clients?.length - 1;
                    const classes = isLast
                      ? "p-4"
                      : "p-4 border-b border-blue-gray-50";
                    const client = {
                      fullName,
                      price,
                      isPaid,
                      reservationId,
                      createdAt,
                      baggage,
                      departureTime,
                      arrivedTime,
                      email,
                      tel,
                      passengers,
                      km,
                      isRoundTrip,
                      paiementMethod,
                    };
                    console.log(client);
                    return (
                      <tr key={reservationId}>
                        <td className={classes}>
                          <div className="flex items-center gap-3">
                            <Avatar
                              src={profil}
                              alt={fullName}
                              size="md"
                              className="border border-blue-gray-50 bg-blue-gray-50/50 object-contain p-1"
                            />
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold"
                            >
                              {fullName}
                            </Typography>
                          </div>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            <Chip
                              size="small"
                              variant="ghost"
                              value={`${price?.$numberDecimal} €`}
                              color={"green"}
                              className="font-bold text-center"
                            />
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {moment(createdAt).format("DD/MM/YYYY")}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <div className="flex gap-4">
                            <Chip
                              size="sm"
                              variant="ghost"
                              value={isPaid ? "Payé" : "En attente"}
                              color={
                                isPaid === true
                                  ? "green"
                                  : isPaid === false
                                  ? "amber"
                                  : "red"
                              }
                            />
                          </div>
                        </td>
                        <td className={classes}>
                          <div className="flex items-center gap-3">
                            <div className="h-9 w-12 rounded-md border border-blue-gray-50 p-1">
                              <Avatar
                                src={
                                  paiementMethod === "cash"
                                    ? imageCash
                                    : imageCard
                                }
                                size="sm"
                                title={paiementMethod}
                                variant="square"
                                className="h-full w-full object-contain p-1"
                              />
                            </div>
                            <div className="flex flex-col">
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal capitalize"
                              >
                                {paiementMethod
                                  ? paiementMethod === "cash"
                                    ? "Paiement au chauffeur"
                                    : "💳 Stripe"
                                  : "N/A"}
                              </Typography>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal opacity-70"
                              >
                                ID: {reservationId || "N/A"}
                              </Typography>
                            </div>
                          </div>
                        </td>
                        <td className={classes}>
                          {isPaid ? (
                            <Tooltip content="Télécharger la facture">
                              <IconButton
                                variant="text"
                                onClick={() => downloadInvoicePDF(client)}
                              >
                                <ArrowDownTrayIcon className="h-4 w-4" />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <button
                              className="text-xs text-white border rounded-md bg_blue_local p-1 px-4"
                              onClick={() =>
                                handleValidatePaiement(reservationId)
                              }
                            >
                              ✅ Valider le paiement ?
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </CardBody>
          <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
            <Button variant="outlined" size="sm">
              Précédent
            </Button>
            <div className="flex items-center gap-2">
              <IconButton variant="outlined" size="sm">
                1
              </IconButton>
              <IconButton variant="text" size="sm">
                2
              </IconButton>
              <IconButton variant="text" size="sm">
                3
              </IconButton>
              <IconButton variant="text" size="sm">
                ...
              </IconButton>
              <IconButton variant="text" size="sm">
                8
              </IconButton>
              <IconButton variant="text" size="sm">
                9
              </IconButton>
              <IconButton variant="text" size="sm">
                10
              </IconButton>
            </div>
            <Button variant="outlined" size="sm">
              Suivant
            </Button>
          </CardFooter>
        </Card>
      )}
    </>
  );
};

export default PaiementList;
