import React, { useEffect, useState } from "react";
import {
  UserGroupIcon,
  MapPinIcon,
  ArrowPathRoundedSquareIcon,
  ChevronDoubleDownIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";
import MiniMap from "./MiniMap";
import axios from "axios";

const ReservationDetail = ({ reservationDetail }) => {
  const [startLatitude, setStartLatitude] = useState(null);
  const [startLongitude, setStartLongitude] = useState(null);
  const [endLatitude, setEndLatitude] = useState(null);
  const [endLongitude, setEndLongitude] = useState(null);

  useEffect(() => {
    axios
      .get("https://nominatim.openstreetmap.org/search", {
        params: {
          q: reservationDetail?.startAddress,
          format: "json",
        },
      })
      .then((response) => {
        const { lat, lon } = response.data[0];
        setStartLatitude(lat);
        setStartLongitude(lon);
      })
      .catch((error) => {
        console.error(error);
      });

    axios
      .get("https://nominatim.openstreetmap.org/search", {
        params: {
          q: reservationDetail?.endAddress,
          format: "json",
        },
      })
      .then((response) => {
        const { lat, lon } = response.data[0];
        setEndLatitude(lat);
        setEndLongitude(lon);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <section className="relative overflow-hidden p-3">
      <div className="mx-auto">
        <div className="mx-auto text-center">
          <p className="font-display text-3xl tracking-tight text-slate-800 sm:text-4xl">
            Réservation du{" "}
            {moment(reservationDetail?.departureTime).format("DD/MM/YYYY")}
          </p>
          <span className="text-sm text-gray-500">
            Créé le :{" "}
            {moment(reservationDetail?.createdAt).format("DD/MM/YYYY")}
          </span>
        </div>
        <ul className="mx-auto mt-2 grid max-w-2xl grid-cols-1 gap-5 text-sm sm:grid-cols-2 md:gap-y-3 lg:max-w-none lg:grid-cols-2">
          <li className="rounded-2xl border border-gray-200 p-8">
            <h3 className="font-bold text-lg text-gray-900 flex items-center">
              <span className="mr-3">
                <UserGroupIcon className="h-6 w-6" />
              </span>
              Fiche client
            </h3>
            <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-3">
              <p className="text-base text-gray-700 font-bold">
                {reservationDetail?.fullName}
              </p>
              <p className="text-base text-gray-700">
                {reservationDetail?.tel}
              </p>
              <p className="text-base text-gray-700">
                {reservationDetail?.email}
              </p>
            </div>
          </li>
          <li className="rounded-2xl border border-gray-200 p-8">
            <h3 className="font-bold text-lg text-gray-900 flex items-center">
              <span className="mr-3">
                <MapPinIcon className="h-6 w-6" />
              </span>
              Trajet -{" "}
              {reservationDetail?.isRoundTrip ? (
                <div className="flex gap-1 items-center">
                  <ArrowPathRoundedSquareIcon className="h-6 w-6" />
                  <span>Aller-Retour</span>
                </div>
              ) : (
                `Aller uniquement`
              )}
            </h3>
            <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-3">
              <p className="text-base text-gray-700 font-bold">
                {reservationDetail?.startAddress}
              </p>
              <p className="text-base text-gray-700">
                {reservationDetail?.endAddress}
              </p>
              <p className="text-base text-gray-700">
                {reservationDetail?.departureTime?.slice(11, 16)} à{" "}
                {reservationDetail?.arrivedTime?.slice(11, 16)}
              </p>
            </div>
            <div className="flex items-center justify-center p-2">
              <ChevronDoubleDownIcon className="h-6 w-6" />
            </div>
            <div className="flex justify-between items-center bg-gray-100 border rounded-xl p-3">
              <p className="text-base text-gray-700 font-bold">
                {reservationDetail?.passengers} passagers
              </p>
              <p className="text-base text-gray-700">
                {reservationDetail?.baggage} bagages
              </p>
              <p className="text-base text-gray-700">
                {reservationDetail?.km} km
              </p>
              <div className="inline-flex items-center justify-between space-x-1 bg-green-100 text-green-800 px-2 py-0.5 rounded-md text-sm">
                <p className="text-base">
                  {reservationDetail?.price?.$numberDecimal} €
                </p>
              </div>
            </div>
            <div className="flex items-center justify-center p-2">
              <ChevronDoubleDownIcon className="h-6 w-6" />
            </div>
            <div className="flex justify-between items-center bg-gray-100 border rounded-xl p-3 mt-2">
              {endLatitude &&
              endLongitude &&
              startLatitude &&
              startLongitude ? (
                <MiniMap
                  startLatitude={endLatitude}
                  startLongitude={endLongitude}
                  endLatitude={startLatitude}
                  endLongitude={startLongitude}
                />
              ) : (
                <span className="text-center">Chargement en cours ...</span>
              )}
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default ReservationDetail;
