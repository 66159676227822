import React, { useState, useEffect } from "react";
import { toast } from "sonner";

const InternetConnectionStatus = () => {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(window.navigator.onLine);
    };

    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, []);

  return (
    <div>
      {!isOnline && toast.error("Vous n'êtes pas connecté à Internet.")}
    </div>
  );
};

export default InternetConnectionStatus;
