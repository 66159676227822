import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "sonner";
const useFetch = (url) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const promise = () =>
          new Promise((resolve) =>
            setTimeout(() => resolve(axios.get(url)), 2000)
          );

        toast.promise(promise, {
          loading: "Synchronisation en cours 🔄",
          success: (data) => {
            const apiData = data?.data;
            setData(apiData);
            setLoading(false);
            return `Données synchronisées 👌`;
          },
          error: "Impossible de récupérer les données 🤯",
        });

        setError(null);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [url]);

  return { data, loading, error };
};

export default useFetch;
