import React, { useState, useEffect, useCallback } from "react";
import MenuParams from "./MenuParams";
import axios from "axios";
import useFetch from "../../../../Component/Hooks/useFetch";
import { toast } from "sonner";
import Loader from "../../Loader/Loader";
import moment from "moment";
import { TrashIcon } from "@heroicons/react/24/solid";

const HorairesParams = ({ userId }) => {
  const [openingHours, setOpeningHours] = useState([]);
  const [widgets, setWidgets] = useState([]);
  const [selectedWidgetId, setSelectedWidgetId] = useState("");
  const [vacationStartDate, setVacationStartDate] = useState("");
  const [vacationEndDate, setVacationEndDate] = useState("");

  const templateHours = [
    {
      dayOfWeek: 1,
      jour: "Lundi",
      isOpen: false,
      morningStart: "",
      morningEnd: "",
      afternoonStart: "",
      afternoonEnd: "",
    },
    {
      dayOfWeek: 2,
      jour: "Mardi",
      isOpen: false,
      morningStart: "",
      morningEnd: "",
      afternoonStart: "",
      afternoonEnd: "",
    },
    {
      dayOfWeek: 3,
      jour: "Mercredi",
      isOpen: false,
      morningStart: "",
      morningEnd: "",
      afternoonStart: "",
      afternoonEnd: "",
    },
    {
      dayOfWeek: 4,
      jour: "Jeudi",
      isOpen: false,
      morningStart: "",
      morningEnd: "",
      afternoonStart: "",
      afternoonEnd: "",
    },
    {
      dayOfWeek: 5,
      jour: "Vendredi",
      isOpen: false,
      morningStart: "",
      morningEnd: "",
      afternoonStart: "",
      afternoonEnd: "",
    },
    {
      dayOfWeek: 6,
      jour: "Samedi",
      isOpen: false,
      morningStart: "",
      morningEnd: "",
      afternoonStart: "",
      afternoonEnd: "",
    },
    {
      dayOfWeek: 7,
      jour: "Dimanche",
      isOpen: false,
      morningStart: "",
      morningEnd: "",
      afternoonStart: "",
      afternoonEnd: "",
    },
  ];

  const jours = [
    "",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
    "Dimanche",
  ];

  const { data, loading } = useFetch(
    `${process.env.REACT_APP_API_URL}/api/widgets/taxi/getWidgets/${userId}`
  );

  const handleWidgetChange = useCallback((event) => {
    setSelectedWidgetId(event.target.value);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/widgets/taxi/open/${selectedWidgetId}`
        );
        if (response?.data?.openDays.length === 0) {
          setOpeningHours(templateHours);
        } else setOpeningHours(response?.data?.openDays);
      } catch (error) {
        toast.error("Erreur lors du chargement des jours d'ouverture");
      }
    };

    if (selectedWidgetId) {
      fetchData();
    }
  }, [selectedWidgetId]);

  const handleToggleSwitch = (index) => {
    const updatedOpeningHours = [...openingHours];
    updatedOpeningHours[index].isOpen = !updatedOpeningHours[index].isOpen;
    setOpeningHours(updatedOpeningHours);
  };

  const handleTimeChange = (index, timeType, value) => {
    const updatedOpeningHours = [...openingHours];
    updatedOpeningHours[index][timeType] = value;
    setOpeningHours(updatedOpeningHours);
  };

  const handleAddAfternoonTime = (index) => {
    const updatedOpeningHours = [...openingHours];
    const currentDay = updatedOpeningHours[index];

    if (
      currentDay.isOpen ||
      (currentDay.afternoonStart !== "" && currentDay.afternoonEnd !== "")
    ) {
      currentDay.showAfternoon = !currentDay.showAfternoon;
    }

    setOpeningHours(updatedOpeningHours);
  };

  const handleSubmitVacation = (e) => {
    e.preventDefault();

    if (!vacationStartDate || !vacationEndDate) {
      toast.error("Veuillez renseigner les dates de début et de fin");
      return;
    }

    try {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/widgets/taxi/widget/${selectedWidgetId}/vacation-mode`,
          {
            startDate: vacationStartDate,
            endDate: vacationEndDate,
          }
        )
        .then(() => {
          toast.success("Vacances enregistrées");
        })
        .catch(() => {
          toast.error("Erreur lors de l'enregistrement des vacances");
        });
    } catch (error) {
      toast.error("Erreur lors de l'enregistrement des vacances");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/widgets/taxi/horaires/${selectedWidgetId}`,
        { openingHours: openingHours }
      )
      .then(() => {
        toast.success("Horaires mis à jour");
      })
      .catch(() => {
        toast.error("Erreur lors de la mise à jour des horaires");
      });
  };

  const handleDelete = () => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/api/widgets/taxi/widget/${selectedWidgetId}/vacation-mode-delete`
      )
      .then(() => {
        setVacationStartDate("");
        setVacationEndDate("");
        toast.success("Vacances supprimées");
      })
      .catch(() => {
        toast.error("Erreur lors de la suppression des vacances");
      });
  };

  const isTodayInVacationRange = () => {
    if (vacationStartDate && vacationEndDate) {
      const today = moment();
      const start = moment(vacationStartDate);
      const end = moment(vacationEndDate);
      return today.isBetween(start, end, null, "[]");
    }
    return false;
  };

  useEffect(() => {
    if (data) {
      setWidgets(data);
      setSelectedWidgetId(data?.widgets[0]?._id);
    }
  }, [data, userId]);

  useEffect(() => {
    if (selectedWidgetId) {
      const fetchVacationData = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/widgets/taxi/widget/${selectedWidgetId}/vacation-mode`
          );
          if (response.data.vacations[0]) {
            setVacationStartDate(
              moment(response?.data?.vacations[0]?.startDate).format(
                "YYYY-MM-DD"
              )
            );
            setVacationEndDate(
              moment(response?.data?.vacations[0].endDate).format("YYYY-MM-DD")
            );
          }
        } catch (error) {
          toast.error("Erreur lors du chargement des dates de vacances");
        }
      };

      fetchVacationData();
    }
  }, [selectedWidgetId]);

  return (
    <div className="flex-col p-2 lg:p-4 w-full">
      <div className="flex gap-4 justify-between ">
        <h1 className="title_local">Paramètres Horaires</h1>
        {widgets?.widgets && (
          <select
            onChange={handleWidgetChange}
            value={selectedWidgetId}
            className="border border-gray-200 rounded-md px-2 py-1 min-w-[200px] sm:w-auto max-h-[40px] text-gray-700 transition-all shadow-sm hover:shadow-sm hover:shadow-indigo-400 hover:-translate-y-px"
          >
            {widgets?.widgets?.map((widget) => (
              <option key={widget._id} value={widget._id}>
                {widget.title}
              </option>
            ))}
          </select>
        )}
      </div>
      <div className="p-2 lg:p-4 flex space-x-0 space-y-4 gap-4 w-full">
        <div className="mt-3 border rounded-xl max-w-[270px] w-full">
          {/* Menu */}
          <MenuParams />
        </div>
        <div className="flex-2 flex-col w-full flex gap-4">
          <div className="flex-2 border rounded-xl w-full p-3">
            <p className="font-bold">🌞 Mode vacances :</p>
            <form onSubmit={handleSubmitVacation} className="flex gap-4 mt-3">
              <input
                type="date"
                value={vacationStartDate}
                onChange={(e) => setVacationStartDate(e.target.value)}
                className="border rounded-md p-2 w-1/2"
              />
              <input
                type="date"
                value={vacationEndDate}
                onChange={(e) => setVacationEndDate(e.target.value)}
                className="border rounded-md p-2 w-1/2"
              />
              {vacationStartDate && vacationEndDate && (
                <button
                  type="button"
                  className="bg-red-400 text-white font-bold py-2 px-4 rounded"
                  onClick={handleDelete}
                >
                  <TrashIcon className="h-4 w-4 custom-white-icon" />
                </button>
              )}
              <button
                type="submit"
                className="bg_blue_local text-white font-bold py-2 px-4 rounded"
              >
                Enregistrer
              </button>
            </form>
          </div>
          <div className="flex-2 border rounded-xl w-full relative">
            {loading && <Loader />}
            {isTodayInVacationRange() && (
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white font-bold z-10 border rounded-md border-gray-100">
                Vous êtes actuellement en vacances
              </div>
            )}
            <form onSubmit={handleSubmit} className="flex flex-col gap-4 p-3">
              {openingHours?.map((day, index) => (
                <div
                  key={day.dayOfWeek}
                  className="flex items-center gap-3 p-2 border rounded-xl border-gray-200"
                >
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id={`toggleSwitch${index}`}
                      checked={day.isOpen}
                      onChange={() => handleToggleSwitch(index)}
                      className="hidden"
                    />
                    <label
                      htmlFor={`toggleSwitch${index}`}
                      className={`relative w-12 h-6 rounded-full cursor-pointer transition-colors duration-200 ease-in-out ${
                        day.isOpen ? "bg_blue_local" : "bg-gray-300"
                      }`}
                    >
                      <span
                        className={`absolute left-1 top-1 w-4 h-4 rounded-full bg-white shadow-md transition-transform duration-200 ease-in-out transform ${
                          day.isOpen ? "translate-x-6" : "translate-x-0"
                        }`}
                      ></span>
                    </label>
                  </div>
                  <label className="ml-5 mr-4 min-w-[150px] font-bold">
                    {day.jour || jours[day.dayOfWeek]}
                  </label>
                  {day.isOpen && (
                    <>
                      <input
                        type="time"
                        value={day.morningStart}
                        onChange={(e) =>
                          handleTimeChange(
                            index,
                            "morningStart",
                            e.target.value
                          )
                        }
                        className="mr-4"
                      />
                      <span>à</span>
                      <input
                        type="time"
                        value={day.morningEnd}
                        onChange={(e) =>
                          handleTimeChange(index, "morningEnd", e.target.value)
                        }
                        className="mr-4"
                      />
                      <button
                        type="button"
                        onClick={() => handleAddAfternoonTime(index)}
                        className="bg-gray-200 text-gray-600 hover:bg-gray-300 px-2 py-1 rounded"
                      >
                        +
                      </button>
                    </>
                  )}
                  {day.showAfternoon ||
                  (day.afternoonStart !== "" && day.afternoonEnd !== "") ? (
                    <>
                      <input
                        type="time"
                        value={day.afternoonStart}
                        onChange={(e) =>
                          handleTimeChange(
                            index,
                            "afternoonStart",
                            e.target.value
                          )
                        }
                        className="mr-4"
                      />
                      <span>à</span>
                      <input
                        type="time"
                        value={day.afternoonEnd}
                        onChange={(e) =>
                          handleTimeChange(
                            index,
                            "afternoonEnd",
                            e.target.value
                          )
                        }
                      />
                    </>
                  ) : null}
                </div>
              ))}

              <button
                type="submit"
                className="mt-4 bg_blue_local text-white font-bold py-2 px-4 rounded"
              >
                Enregistrer
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HorairesParams;
