import React, { useState, useEffect, useCallback, useMemo } from "react";
import Calendar from "react-calendar";
import Reservation from "./Reservation";
import "../../../Style/Calendar.css";
import axios from "axios";
import Modal from "../../../Component/Modal/Modal";
import CreateReservations from "./CreateReservations";
import useFetch from "../../../Component/Hooks/useFetch";
import { isSameDay } from "date-fns";
import Loader from "../Loader/Loader";
import { toast } from "sonner";

const Planning = ({ userId }) => {
  const [widgets, setWidgets] = useState([]);
  const [selectedWidgetId, setSelectedWidgetId] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [reservationDetails, setReservationDetails] = useState(null);
  const [openDays, setOpenDays] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [reservedDays, setReservedDays] = useState([]);

  const { data, loading } = useFetch(
    `${process.env.REACT_APP_API_URL}/api/widgets/taxi/getWidgets/${userId}`
  );

  const loadOpenDays = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/widgets/taxi/open/${selectedWidgetId}`
      );
      setOpenDays(response?.data);
    } catch (error) {
      console.error("Error loading open days:", error);
    }
  }, [selectedWidgetId]);

  const loadReservationDetails = useCallback(async () => {
    try {
      const formattedDate = selectedDate?.toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/widgets/taxi/reservations/${selectedWidgetId}?date=${formattedDate}`
      );

      setReservationDetails(response?.data);
    } catch (error) {
      toast.error("Erreur lors du chargement des détails de réservation");
    }
  }, [selectedDate, selectedWidgetId]);

  const fetchReservationDetails = useCallback(async () => {
    if (selectedWidgetId) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/widgets/taxi/reservations/all/${selectedWidgetId}`
        );
        const reservations = response?.data?.reservations;

        // Filtrer les réservations validées
        const validatedReservations = reservations?.filter(
          (reservation) => reservation.isValidated
        );

        const reservedDays = validatedReservations?.map((reservation) => {
          const departureDate = new Date(reservation?.departureTime);
          return departureDate.getDate();
        });

        setReservedDays(reservedDays);
      } catch (error) {
        toast.error("Erreur lors du chargement des réservations");
      }
    }
  }, [selectedWidgetId]);

  const isDayDisabled = useCallback(
    ({ date }) => {
      const dayOfWeek = date?.getDay();
      const isOpen = openDays?.openDays?.find(
        (day) => day?.dayOfWeek === dayOfWeek && day?.isOpen === true
      );
      return !isOpen;
    },
    [openDays]
  );

  const dayTileClassName = useCallback(
    ({ date }) => {
      const dayOfWeek = date?.getDay();
      const isOpen = openDays?.openDays?.find(
        (day) => day?.dayOfWeek === dayOfWeek && day?.isOpen === true
      );
      return !isOpen ? "disabled-day" : "";
    },
    [openDays]
  );

  const handleCreateReservation = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleWidgetChange = useCallback((event) => {
    setSelectedWidgetId(event.target.value);
  }, []);

  const handleDateChange = useCallback((date) => {
    setSelectedDate(date);
  }, []);

  const show = useMemo(
    () =>
      ({ date, view }) => {
        if (view === "month") {
          const today = new Date();
          if (isSameDay(date, today)) {
            return <div className="today-marker"></div>;
          }
          const dayOfMonth = date.getDate();
          const hasReservation = reservedDays?.includes(dayOfMonth);

          if (hasReservation) {
            return <div className="reservation-marker"></div>;
          }
        }
        return null;
      },
    [reservedDays]
  );

  useEffect(() => {
    if (data) {
      setWidgets(data);
      setSelectedWidgetId(data?.widgets[0]?._id);
    }
  }, [data, userId]);

  useEffect(() => {
    if (selectedWidgetId) {
      loadOpenDays();
      loadReservationDetails();
    }
  }, [selectedWidgetId, loadOpenDays, loadReservationDetails]);

  useEffect(() => {
    if (selectedWidgetId) {
      fetchReservationDetails();
    }
  }, [selectedWidgetId, fetchReservationDetails]);

  return (
    <div className="flex-col p-2 lg:p-4 w-full">
      <div className="flex gap-4 justify-between">
        <h1 className="title_local">Mon Agenda</h1>
        {widgets?.widgets && (
          <select
            onChange={handleWidgetChange}
            value={selectedWidgetId}
            className="border border-gray-200 rounded-md px-2 py-1 min-w-[200px] sm:w-auto max-h-[40px] text-gray-700 transition-all shadow-sm hover:shadow-sm hover:shadow-indigo-400 hover:-translate-y-px"
          >
            {widgets?.widgets?.map((widget) => (
              <option key={widget._id} value={widget._id}>
                {widget?.title}
              </option>
            ))}
          </select>
        )}
        <button
          onClick={handleCreateReservation}
          className="inline-block w-auto min-w-[250px] py-1 max-h-[40px] text-white transition-all rounded-md shadow-xl sm:w-auto bg-[#00073d] shadow-indigo-200 hover:shadow-2xl hover:shadow-indigo-400 hover:-translate-y-px "
        >
          Créer une réservation
        </button>
      </div>

      <div className="border p-2 lg:p-4 rounded-xl flex space-x-0 space-y-4  w-full">
        <div className="mr-6 mt-3">
          {/* Calendrier */}
          <Calendar
            onChange={handleDateChange}
            value={selectedDate}
            tileDisabled={isDayDisabled}
            tileClassName={dayTileClassName}
            tileContent={show}
          />
        </div>
        <div style={{ flex: "2" }}>
          {/* Affichage des détails de réservation */}
          {reservationDetails ? (
            <Reservation reservation={reservationDetails} />
          ) : (
            loading && <Loader />
          )}
        </div>
      </div>
      {/* Modal de création de réservation */}
      {showModal && (
        <Modal
          onClose={handleCloseModal}
          isOpen={showModal}
          children={
            <CreateReservations
              widgetId={selectedWidgetId}
              onClose={handleCloseModal}
            />
          }
        />
      )}
    </div>
  );
};

export default Planning;
