import React from "react";
import useFetch from "../../../Component/Hooks/useFetch";
import moment from "moment";
import Loader from "../Loader/Loader";

const Statistiques = ({ widgetId }) => {
  const { loading, data } = useFetch(
    `${process.env.REACT_APP_API_URL}/api/widgets/taxi/getClicks/${widgetId}`
  );

  const today = moment().format("YYYY-MM-DD");
  const month = moment().format("YYYY-MM");
  const year = moment().format("YYYY");

  const todayClicks = data?.stats?.filter((click) => {
    return click.date.includes(today);
  });
  const monthClicks = data?.stats?.filter((click) => {
    return click.date.includes(month);
  });
  const yearClicks = data?.stats?.filter((click) => {
    return click.date.includes(year);
  });

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="bg-gray-50 py-16 pt-5">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto text-center">
              <h2 className="title_local">Statistiques du simulateur</h2>
            </div>
          </div>
          <div className="mt-10 pb-1">
            <div className="relative">
              <div className="absolute inset-0 h-1/2 bg-gray-50"></div>
              <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="max-w-4xl mx-auto">
                  <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
                    <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                      <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                        Nombre de clics aujourd'hui
                      </dt>
                      <dd className="order-1 text-5xl font-extrabold text-gray-700">
                        {todayClicks?.length}
                      </dd>
                    </div>
                    <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                      <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                        Nombre de clics sur le mois
                      </dt>
                      <dd className="order-1 text-5xl font-extrabold text-gray-700">
                        {monthClicks?.length}
                      </dd>
                    </div>
                    <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                      <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                        Nombre de clics sur l'année
                      </dt>
                      <dd className="order-1 text-5xl font-extrabold text-gray-700">
                        {yearClicks?.length}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Statistiques;
