import React from "react";
import Lottie from "lottie-react";
import animationData from "../../../asset/loadingCar.json";

const CarLoader = () => {
  return (
    <div className="w-[935px] flex flex-col items-center justify-center gap-4">
      <Lottie
        animationData={animationData}
        className="mx-10 h-auto w-[600px]"
        loop={false}
      />
      <p className="text-xl font-bold">Chargement du widget ...</p>
    </div>
  );
};

export default CarLoader;
