import React, { useState } from "react";
import axios from "axios";
import { toast } from "sonner";
import moment from "moment";
import {
  UserGroupIcon,
  MapPinIcon,
  CurrencyEuroIcon,
  ShoppingBagIcon,
  CheckBadgeIcon,
  BanknotesIcon,
} from "@heroicons/react/24/outline";

const ReservationForm = ({
  widgetId,
  widgetData,
  setShowReservationForm,
  paiementMethod,
}) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [reservationData, setReservationData] = useState({
    fullName: "",
    email: "",
    tel: "",
    startAddress: widgetData?.startAddress,
    endAddress: widgetData?.endAddress,
    passengers: widgetData?.passengers,
    baggage: widgetData?.baggage,
    departureTime: widgetData?.departureTime,
    arrivedTime: widgetData?.arrivedTime,
    isRoundTrip: widgetData?.isRoundTrip,
    price: widgetData?.price,
    km: widgetData?.km,
    paiementMethod: selectedPaymentMethod,
  });
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Rechercher le client en fonction du nom, email et numéro de téléphone
      const clientResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/widgets/taxi/widget/${widgetId}/client/search`,
        {
          params: {
            clientEmail: reservationData.email,
            clientTel: reservationData.tel,
          },
        }
      );

      let clientId;
      let reservationId;

      // Si le client existe déjà, récupérer son ID
      if (clientResponse.data.clients.length > 0) {
        clientId = clientResponse.data.clients[0]._id;

        // Créez la réservation et récupérez son ID
        const reservationResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/widgets/taxi/reservations/${widgetId}`,
          {
            reservation: { ...reservationData, clientId: clientId },
          }
        );

        reservationId = reservationResponse.data.reservationId; // Stockez l'ID de la réservation
      } else {
        // Si le client n'existe pas, le créer et créer la réservation
        const newClientResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/widgets/taxi/client/widget/${widgetId}`,
          {
            fullName: reservationData.fullName,
            email: reservationData.email,
            tel: reservationData.tel,
          }
        );

        clientId = newClientResponse.data.clients[0]._id;
        reservationId = newClientResponse.data.clients[0].reservations[0]._id;
      }

      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/widgets/taxi/client/widget/${widgetId}/client/${clientId}`,
        {
          reservation: { ...reservationData, reservationId: reservationId },
        }
      );

      setSuccess(true);
    } catch (error) {
      toast.error("Erreur lors de la création de la réservation");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setReservationData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePaiementhange = (e) => {
    const { value } = e.target;
    setSelectedPaymentMethod(value);
    setReservationData((prevData) => ({
      ...prevData,
      paiementMethod: value,
    }));
  };

  return (
    <div className="flex justify-between gap-4 w-full relative">
      {success && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-80 text-white font-bold z-10 border rounded-md border-gray-100 flex-col">
          <CheckBadgeIcon className="h-10 w-10 custom-green-icon" />
          <br />
          Votre demande de réservation est bien enregistrée. <br />
          Vous serez contacté par le chauffeur pour confirmer votre réservation.
          <br />
          Un mail de confirmation vous a été envoyé.
          <button
            className="mt-6 inline-block w-auto min-w-[250px] max-h-[40px] py-2 text-white transition-all rounded-md shadow-lg sm:w-auto bg-[#558b3d] hover:bg-green-400"
            onClick={() => setShowReservationForm()}
          >
            Fermer
          </button>
        </div>
      )}
      <div className="flex justify-between gap-4 mt-4 w-full">
        {/* // Affichage des informations de la réservation */}
        <div className="w-1/2 pl-4 rounded-2xl border border-gray-200 p-8">
          <div className="flex justify-between items-center">
            <h3 className="font-bold text-lg text-gray-900 flex items-center">
              <span className="mr-3">
                <MapPinIcon className="h-6 w-6" />
              </span>
              Trajet
            </h3>
            <div className="flex items-center">
              <p className="font-bold mr-3">Aller-retour ?</p>
              <div className="p-1 border px-6 border-gray-200 rounded-xl w-[100px] bg-white">
                {reservationData.isRoundTrip ? "Oui" : "Non"}
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-3">
            <label htmlFor="startAdress">Départ :</label>
            <div className="p-1 border px-6 border-gray-200 rounded-xl w-[400px] bg-white">
              {reservationData.startAddress}
            </div>
          </div>
          <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-3">
            <label htmlFor="endAdress">Destination :</label>
            <div className="p-1 border px-6 border-gray-200 rounded-xl w-[400px] bg-white">
              {reservationData.endAddress}
            </div>
          </div>
          <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-3">
            <label htmlFor="departureTime">Heure de départ :</label>
            <div className="p-1 border px-6 border-gray-200 rounded-xl w-[400px] bg-white">
              {moment(reservationData.departureTime).format(
                "YYYY-MM-DD à HH:mm"
              )}
            </div>
          </div>
          <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-3">
            <label htmlFor="arrivedTime">Heure d'arrivée :</label>
            <div className="p-1 border px-6 border-gray-200 rounded-xl w-[400px] bg-white">
              {moment(reservationData.arrivedTime).format("YYYY-MM-DD à HH:mm")}
            </div>
          </div>
          <div className="flex gap-2">
            <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-3 gap-4 w-full">
              <label htmlFor="baggage">Bagages :</label>
              <div className="flex gap-2 items-center">
                <ShoppingBagIcon className="h-5 w-5" />
                <div className="p-1 border px-6 border-gray-200 rounded-xl w-[100px] bg-white">
                  {reservationData.baggage}
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-3 gap-4 w-full">
              <label htmlFor="passengers">Passagers :</label>
              <div className="flex gap-2 items-center">
                <UserGroupIcon className="h-5 w-5" />
                <div className="p-1 border px-6 border-gray-200 rounded-xl w-[100px] bg-white">
                  {reservationData.passengers}
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-2">
            <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-3 gap-4 w-full">
              <label htmlFor="km">Kilomètres :</label>
              <div className="flex gap-2 items-center">
                <MapPinIcon className="h-5 w-5" />
                <div className="p-1 border px-6 border-gray-200 rounded-xl w-[100px] bg-white">
                  {reservationData.km}
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-3 gap-4 w-full">
              <label htmlFor="price">Prix :</label>
              <div className="flex gap-2 items-center">
                <CurrencyEuroIcon className="h-5 w-5" />
                <div className="p-1 border px-6 border-gray-200 rounded-xl w-[100px] bg-white">
                  {reservationData.price}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* // Affichage du formulaire de réservation */}
        <div className="flex justify-between gap-4 w-1/2">
          <form onSubmit={handleSubmit} className="w-full">
            <div className=" pr-4 rounded-2xl border border-gray-200 p-8 w-full">
              <h3 className="font-bold text-lg text-gray-900 flex items-center">
                <span className="mr-3">
                  <UserGroupIcon className="h-6 w-6" />
                </span>
                Compléter ma réservation
              </h3>
              <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-3">
                <label htmlFor="fullName">Nom complet :</label>
                <input
                  type="text"
                  name="fullName"
                  className="p-1 border px-6 border-gray-200 rounded-xl w-[330px]"
                  value={reservationData.fullName}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-3">
                <label htmlFor="tel">Téléphone :</label>
                <input
                  type="tel"
                  name="tel"
                  className="p-1 border px-6 border-gray-200 rounded-xl w-[330px]"
                  value={reservationData.tel}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-3">
                <label htmlFor="email">Email :</label>
                <input
                  type="email"
                  name="email"
                  className="p-1 border px-6 border-gray-200 rounded-xl w-[330px]"
                  value={reservationData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            {paiementMethod?.card || paiementMethod?.cash ? (
              <div className=" pr-4 rounded-2xl border border-gray-200 p-8 w-full flex flex-col gap-4 mt-4">
                <h3 className="font-bold text-lg text-gray-900 flex items-center">
                  <span className="mr-3">
                    <BanknotesIcon className="h-6 w-6" />
                  </span>
                  Mode de paiement
                </h3>
                <div className="flex gap-4">
                  {paiementMethod?.card && (
                    <>
                      <input
                        type="checkbox"
                        id="card"
                        name="paiementMethod"
                        className={`hidden peer ${
                          selectedPaymentMethod === "card"
                            ? "selected-payment-method"
                            : ""
                        }`}
                        value="card"
                        checked={selectedPaymentMethod === "card"}
                        onChange={handlePaiementhange}
                      />
                      <label
                        htmlFor="card"
                        className={`select-none cursor-pointer flex items-center justify-center rounded-lg border-2 border-gray-200 w-full
py-3 px-6 font-bold text-gray-700 transition-colors duration-200 ease-in-out  
${selectedPaymentMethod === "card" ? "selected-payment-method" : ""}`}
                      >
                        <span
                          className={`${
                            selectedPaymentMethod === "card" ? "text-white" : ""
                          }`}
                        >
                          En ligne
                        </span>
                      </label>
                    </>
                  )}

                  {paiementMethod?.cash && (
                    <>
                      <input
                        type="checkbox"
                        id="cash"
                        name="paiementMethod"
                        className={`hidden peer ${
                          selectedPaymentMethod === "cash"
                            ? "selected-payment-method"
                            : ""
                        }`}
                        value="cash"
                        checked={selectedPaymentMethod === "cash"}
                        onChange={handlePaiementhange}
                      />
                      <label
                        htmlFor="cash"
                        className={`select-none cursor-pointer flex items-center justify-center rounded-lg border-2 border-gray-200 w-full
py-3 px-6 font-bold text-gray-700 transition-colors duration-200 ease-in-out
${selectedPaymentMethod === "cash" ? "selected-payment-method" : ""}`}
                      >
                        <span
                          className={`${
                            selectedPaymentMethod === "cash" ? "text-white" : ""
                          }`}
                        >
                          Au chauffeur
                        </span>
                      </label>
                    </>
                  )}
                </div>
              </div>
            ) : null}

            <div className="flex justify-center items-center border rounded-xl mt-6 p-3">
              <button
                type="submit"
                className="inline-block w-auto min-w-[250px] max-h-[40px] py-2 text-white transition-all rounded-md shadow-lg sm:w-auto bg-[#00073d] shadow-indigo-200 hover:shadow-lg hover:shadow-indigo-400 hover:-translate-y-px "
              >
                Réserver maintenant !
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ReservationForm;
