import React, { useState } from "react";
import {
  PhoneIcon,
  UserGroupIcon,
  ShoppingBagIcon,
  ClockIcon,
  EyeIcon,
  CheckCircleIcon,
  PauseCircleIcon,
  EllipsisVerticalIcon,
  CurrencyEuroIcon,
} from "@heroicons/react/24/outline";
import { Tooltip, IconButton } from "@material-tailwind/react";
import Modal from "../../../Component/Modal/Modal";
import ReservationDetail from "./ReservationDetail";
import axios from "axios";
import { toast } from "sonner";

const Reservation = ({ reservation, widgetId, loadReservationDetails }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const morningReservations = reservation?.reservations?.filter((res) => {
    const departureTime = new Date(res?.departureTime);
    const morningStart = new Date(
      `${res?.departureTime?.split("T")[0]}T00:00:00.000Z`
    );
    const morningEnd = new Date(
      `${res?.departureTime?.split("T")[0]}T12:00:00.000Z`
    );
    return departureTime >= morningStart && departureTime < morningEnd;
  });

  const afternoonReservations = reservation?.reservations?.filter((res) => {
    const departureTime = new Date(res?.departureTime);
    const afternoonStart = new Date(
      `${res?.departureTime?.split("T")[0]}T12:00:00.000Z`
    );
    const afternoonEnd = new Date(
      `${res?.departureTime?.split("T")[0]}T19:00:00.000Z`
    );
    return departureTime >= afternoonStart && departureTime < afternoonEnd;
  });

  const eveningReservations = reservation?.reservations?.filter((res) => {
    const departureTime = new Date(res?.departureTime);
    const eveningStart = new Date(
      `${res?.departureTime?.split("T")[0]}T19:00:00.000Z`
    );
    const eveningEnd = new Date(
      `${res?.departureTime?.split("T")[0]}T23:59:59.999Z`
    );
    return departureTime >= eveningStart && departureTime <= eveningEnd;
  });

  const toggleMorningModal = (widgetId) => {
    setIsModalOpen((prev) => ({ ...prev, [widgetId]: !prev[widgetId] }));
  };

  const toggleAfternoonModal = (widgetId) => {
    setIsModalOpen((prev) => ({ ...prev, [widgetId]: !prev[widgetId] }));
  };

  const toggleEveningModal = (widgetId) => {
    setIsModalOpen((prev) => ({ ...prev, [widgetId]: !prev[widgetId] }));
  };

  const toggleSubMenu = (widgetId) => {
    setIsSubMenuOpen((prev) => ({ ...prev, [widgetId]: !prev[widgetId] }));
  };

  const cancelReservation = async (reservationId) => {
    try {
      const confirmDelete = window.confirm(
        "Êtes-vous sûr de vouloir supprimer cette réservation ?"
      );
      if (confirmDelete) {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/api/widgets/taxi/reservations/delete/${reservationId}/widget/${widgetId}`
        );
        toast.success("La réservation a été supprimée avec succès");
        loadReservationDetails();
      } else {
        toast.error("Annulation de l'opération de suppression");
      }
    } catch (error) {
      toast.error("Erreur lors de l'annulation de la réservation");
    }
  };

  const handleAcceptReservation = async (reservationId) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/widgets/taxi/reservations/accept/${reservationId}/widget/${widgetId}`
      );
      toast.success("La réservation a été validée avec succès");
      const updatedReservation = reservation?.reservations?.find(
        (res) => res._id === reservationId
      );
      updatedReservation.isValidated = true;
      loadReservationDetails();
    } catch (error) {
      if (error?.response?.status === 400) {
        toast.error(
          "Hors des heures d'ouverture, veuillez configurer vos horaires"
        );
      }
      if (error?.response?.status === 401) {
        toast.error(
          "Cette réservation entre en conflit avec une autre réservation déjà acceptée. Veuillez vérifier vos réservations et les horaires d'ouverture."
        );
      }
    }
  };

  return (
    <div className="flex-col flex gap-5 w-full">
      <div className="border rounded-xl p-4">
        <div className="flex items-center justify-between space-x-3 mb-1">
          <div className="flex space-y-2">
            <div className="text-xl font-bold">Matin</div>
          </div>
        </div>
        <div className="flex w-full flex-col space-y-2 mt-4">
          {morningReservations?.length > 0 ? (
            morningReservations?.map((morningReservation) => (
              <div
                className="flex w-full justify-between items-center bg-gray-100 p-3 border rounded-xl"
                key={morningReservation?._id}
              >
                <div className="flex-col gap-1">
                  <p className="font-bold">{morningReservation?.fullName}</p>
                  <p className="flex gap-2 items-center text-sm">
                    <PhoneIcon className="h-4 w-4" /> {morningReservation?.tel}
                  </p>
                </div>
                <div className="flex-col">
                  <p className="flex gap-2 items-center">
                    <UserGroupIcon className="h-6 w-6" />
                    <span className="font-bold">
                      {morningReservation?.passengers}
                    </span>
                  </p>
                </div>
                <div className="flex-col">
                  <p className="flex gap-2 items-center">
                    <ShoppingBagIcon className="h-6 w-6" />
                    <span className="font-bold">
                      {morningReservation?.baggage}
                    </span>
                  </p>
                </div>
                <div className="flex-col">
                  <p className="flex gap-2 items-center">
                    <ClockIcon className="h-6 w-6 font-extralight" />
                    <span className="font-bold">
                      {morningReservation?.departureTime?.slice(0, 10)}
                    </span>
                    <span className="font-bold">
                      {morningReservation?.departureTime?.slice(11, 16)}
                    </span>
                    <span className="font-bold">
                      à {morningReservation?.arrivedTime?.slice(11, 16)}
                    </span>
                  </p>
                </div>
                <div className="flex-col">
                  <p className="flex gap-2 items-center">
                    <CurrencyEuroIcon className="h-6 w-6 font-extralight" />
                    <span className="font-bold">
                      {morningReservation?.price?.$numberDecimal}{" "}
                      {morningReservation?.isPaid ? (
                        <span className="inline-flex items-center justify-between space-x-1 bg-green-100 text-green-800 px-2 py-0.5 rounded-md text-sm ml-10">
                          Payé
                        </span>
                      ) : (
                        <span className="inline-flex items-center justify-between space-x-1 bg-orange-100 text-orange-800 px-2 py-0.5 rounded-md text-sm ml-4">
                          A Payer
                        </span>
                      )}
                    </span>
                  </p>
                </div>
                <div className="flex-col">
                  <p className="flex gap-2 items-center">
                    {morningReservation?.isValidated ? (
                      <CheckCircleIcon className="h-6 w-6 font-extralight" />
                    ) : (
                      <PauseCircleIcon className="h-6 w-6 font-extralight" />
                    )}

                    <span className="font-bold">
                      {morningReservation?.isValidated ? (
                        <span className="inline-flex items-center justify-between space-x-1 bg-green-100 text-green-800 px-2 py-0.5 rounded-md text-sm">
                          Validé
                        </span>
                      ) : (
                        <span className="inline-flex items-center justify-between space-x-1 bg-orange-100 text-orange-800 px-2 py-0.5 rounded-md text-sm">
                          En attente de validation{" "}
                        </span>
                      )}
                    </span>
                  </p>
                </div>
                <div className="flex-col">
                  <div className="flex gap-2 items-center relative">
                    <Tooltip content="Confirmer la réservation">
                      <IconButton
                        variant="text"
                        className="bg-[#00073d]"
                        onClick={() => toggleSubMenu(morningReservation?._id)}
                      >
                        <EllipsisVerticalIcon
                          className="h-4 w-4"
                          stroke="white"
                        />
                      </IconButton>
                    </Tooltip>
                    {isSubMenuOpen[morningReservation?._id] && (
                      <div className="absolute right-12 top-12 w-56 rounded-md shadow-lg shadow-black bg-white ring-1 ring-black ring-opacity-5 z-10 ">
                        <div className="py-1">
                          {/* Options du sous-menu */}
                          {morningReservation?.isValidated ? null : (
                            <button
                              onClick={() =>
                                handleAcceptReservation(morningReservation?._id)
                              }
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left border-b-2 border-gray-100"
                            >
                              ✅ Confirmer la réservation
                            </button>
                          )}
                          <button
                            onClick={() =>
                              cancelReservation(
                                morningReservation?.reservationId
                              )
                            }
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left border-b-2 border-gray-100"
                          >
                            ⛔ Annuler la réservation
                          </button>
                          <button
                            onClick={() =>
                              toggleSubMenu(morningReservation?._id)
                            }
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                          >
                            Ne rien faire pour le moment
                          </button>
                        </div>
                      </div>
                    )}
                    <Tooltip content="Voir la réservation">
                      <IconButton
                        variant="text"
                        className="bg-[#00073d]"
                        onClick={() =>
                          toggleMorningModal(morningReservation?._id)
                        }
                      >
                        <EyeIcon className="h-4 w-4" stroke="white" />
                      </IconButton>
                    </Tooltip>
                    {isModalOpen[morningReservation?._id] && (
                      <Modal
                        isOpen={isModalOpen[morningReservation?._id]}
                        onClose={() =>
                          toggleMorningModal(morningReservation?._id)
                        }
                      >
                        <ReservationDetail
                          reservationDetail={morningReservation}
                        />
                      </Modal>
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="text-red-300">Aucune réservation le matin.</p>
          )}
        </div>
      </div>

      <div className="border rounded-xl p-4">
        <div className="flex items-center justify-between space-x-3 mb-1">
          <div className="flex space-y-2">
            <div className="text-xl font-bold">Midi</div>
          </div>
        </div>
        <div className="flex w-full flex-col space-y-2">
          {afternoonReservations?.length > 0 ? (
            afternoonReservations?.map((afternoonReservations) => (
              <div
                className="flex w-full justify-between items-center bg-gray-100 p-3 border rounded-xl"
                key={afternoonReservations?._id}
              >
                <div className="flex-col gap-1">
                  <p className="font-bold">{afternoonReservations?.fullName}</p>
                  <p className="flex gap-2 items-center text-sm">
                    <PhoneIcon className="h-4 w-4" />{" "}
                    {afternoonReservations?.tel}
                  </p>
                </div>
                <div className="flex-col">
                  <p className="flex gap-2 items-center font-bold">
                    <UserGroupIcon className="h-6 w-6" />
                    {afternoonReservations?.passengers}
                  </p>
                </div>
                <div className="flex-col">
                  <p className="flex gap-2 items-center font-bold">
                    <ShoppingBagIcon className="h-6 w-6" />
                    {afternoonReservations?.baggage}
                  </p>
                </div>
                <div className="flex-col">
                  <p className="flex gap-2 items-center">
                    <ClockIcon className="h-6 w-6 font-extralight" />
                    <span className="font-bold">
                      {afternoonReservations?.departureTime?.slice(0, 10)}
                    </span>
                    <span className="font-bold">
                      {afternoonReservations?.departureTime?.slice(11, 16)}
                    </span>
                    <span className="font-bold">
                      à {afternoonReservations?.arrivedTime?.slice(11, 16)}
                    </span>
                  </p>
                </div>
                <div className="flex-col">
                  <p className="flex gap-2 items-center">
                    <CurrencyEuroIcon className="h-6 w-6 font-extralight" />
                    <span className="font-bold">
                      {afternoonReservations?.price?.$numberDecimal}{" "}
                      {afternoonReservations?.isPaid ? (
                        <span className="inline-flex items-center justify-between space-x-1 bg-green-100 text-green-800 px-2 py-0.5 rounded-md text-sm ml-10">
                          Payé
                        </span>
                      ) : (
                        <span className="inline-flex items-center justify-between space-x-1 bg-orange-100 text-orange-800 px-2 py-0.5 rounded-md text-sm ml-4">
                          A Payer
                        </span>
                      )}
                    </span>
                  </p>
                </div>
                <div className="flex-col">
                  <p className="flex gap-2 items-center">
                    {afternoonReservations?.isValidated ? (
                      <CheckCircleIcon className="h-6 w-6 font-extralight" />
                    ) : (
                      <PauseCircleIcon className="h-6 w-6 font-extralight" />
                    )}

                    <span className="font-bold">
                      {afternoonReservations?.isValidated ? (
                        <span className="inline-flex items-center justify-between space-x-1 bg-green-100 text-green-800 px-2 py-0.5 rounded-md text-sm">
                          Validé
                        </span>
                      ) : (
                        <span className="inline-flex items-center justify-between space-x-1 bg-orange-100 text-orange-800 px-2 py-0.5 rounded-md text-sm">
                          En attente de validation{" "}
                        </span>
                      )}
                    </span>
                  </p>
                </div>
                <div className="flex-col relative">
                  <div className="flex gap-2 items-center">
                    <Tooltip content="Confirmer la réservation">
                      <IconButton
                        variant="text"
                        className="bg-[#00073d]"
                        onClick={() =>
                          toggleSubMenu(afternoonReservations?._id)
                        }
                      >
                        <EllipsisVerticalIcon
                          className="h-4 w-4"
                          stroke="white"
                        />
                      </IconButton>
                    </Tooltip>

                    {isSubMenuOpen[afternoonReservations?._id] && (
                      <div className="absolute right-12 top-12 w-56 rounded-md shadow-lg shadow-black bg-white ring-1 ring-black ring-opacity-5 z-10 ">
                        <div className="py-1">
                          {/* Options du sous-menu */}
                          {afternoonReservations?.isValidated ? null : (
                            <button
                              onClick={() =>
                                handleAcceptReservation(
                                  afternoonReservations?._id
                                )
                              }
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left border-b-2 border-gray-100"
                            >
                              ✅ Confirmer la réservation
                            </button>
                          )}
                          <button
                            onClick={() =>
                              cancelReservation(
                                afternoonReservations?.reservationId
                              )
                            }
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left border-b-2 border-gray-100"
                          >
                            ⛔ Annuler la réservation
                          </button>
                          <button
                            onClick={() =>
                              toggleSubMenu(afternoonReservations?._id)
                            }
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                          >
                            Ne rien faire pour le moment
                          </button>
                        </div>
                      </div>
                    )}
                    <Tooltip content="Voir la réservation">
                      <IconButton
                        variant="text"
                        className="bg-[#00073d]"
                        onClick={() =>
                          toggleAfternoonModal(afternoonReservations?._id)
                        }
                      >
                        <EyeIcon className="h-4 w-4" stroke="white" />
                      </IconButton>
                    </Tooltip>

                    {isModalOpen[afternoonReservations?._id] && (
                      <Modal
                        isOpen={isModalOpen[afternoonReservations?._id]}
                        onClose={() =>
                          toggleAfternoonModal(afternoonReservations?._id)
                        }
                      >
                        <ReservationDetail
                          reservationDetail={afternoonReservations}
                        />
                      </Modal>
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="text-red-300">Aucune réservation l'après-midi.</p>
          )}
        </div>
      </div>

      <div className="border rounded-xl p-4">
        <div className="flex items-center justify-between space-x-3 mb-1">
          <div className="flex space-y-2">
            <div className="text-xl font-bold">Soir/Nuit</div>
          </div>
        </div>
        <div className="flex w-full flex-col space-y-2">
          {eveningReservations?.length > 0 ? (
            eveningReservations?.map((eveningReservations) => (
              <div
                className="flex w-full justify-between items-center bg-gray-100 p-3 border rounded-xl"
                key={eveningReservations?._id}
              >
                <div className="flex-col gap-1">
                  <p className="font-bold">{eveningReservations?.fullName}</p>
                  <p className="flex gap-2 items-center text-sm">
                    <PhoneIcon className="h-4 w-4" /> {eveningReservations?.tel}
                  </p>
                </div>
                <div className="flex-col">
                  <p className="flex gap-2 items-center font-bold">
                    <UserGroupIcon className="h-6 w-6" />
                    {eveningReservations?.passengers}
                  </p>
                </div>
                <div className="flex-col">
                  <p className="flex gap-2 items-center font-bold">
                    <ShoppingBagIcon className="h-6 w-6" />
                    {eveningReservations?.baggage}
                  </p>
                </div>
                <div className="flex-col">
                  <p className="flex gap-2 items-center">
                    <ClockIcon className="h-6 w-6 font-extralight" />
                    <span className="font-bold">
                      {eveningReservations?.departureTime?.slice(0, 10)}
                    </span>
                    <span className="font-bold">
                      {eveningReservations?.departureTime?.slice(11, 16)}
                    </span>
                    <span className="font-bold">
                      à {eveningReservations?.arrivedTime?.slice(11, 16)}
                    </span>
                  </p>
                </div>
                <div className="flex-col">
                  <p className="flex gap-2 items-center">
                    <CurrencyEuroIcon className="h-6 w-6 font-extralight" />
                    <span className="font-bold">
                      {eveningReservations?.price?.$numberDecimal}{" "}
                      {eveningReservations?.isPaid ? (
                        <span className="inline-flex items-center justify-between space-x-1 bg-green-100 text-green-800 px-2 py-0.5 rounded-md text-sm ml-10">
                          Payé
                        </span>
                      ) : (
                        <span className="inline-flex items-center justify-between space-x-1 bg-orange-100 text-orange-800 px-2 py-0.5 rounded-md text-sm ml-4">
                          A Payer
                        </span>
                      )}
                    </span>
                  </p>
                </div>
                <div className="flex-col">
                  <p className="flex gap-2 items-center">
                    {eveningReservations?.isValidated ? (
                      <CheckCircleIcon className="h-6 w-6 font-extralight" />
                    ) : (
                      <PauseCircleIcon className="h-6 w-6 font-extralight" />
                    )}

                    <span className="font-bold">
                      {eveningReservations?.isValidated ? (
                        <span className="inline-flex items-center justify-between space-x-1 bg-green-100 text-green-800 px-2 py-0.5 rounded-md text-sm">
                          Validé
                        </span>
                      ) : (
                        <span className="inline-flex items-center justify-between space-x-1 bg-orange-100 text-orange-800 px-2 py-0.5 rounded-md text-sm">
                          En attente de validation{" "}
                        </span>
                      )}
                    </span>
                  </p>
                </div>
                <div className="flex-col relative">
                  <div className="flex gap-2 items-center">
                    <Tooltip content="Confirmer la réservation">
                      <IconButton
                        variant="text"
                        className="bg-[#00073d]"
                        onClick={() => toggleSubMenu(eveningReservations?._id)}
                      >
                        <EllipsisVerticalIcon
                          className="h-4 w-4"
                          stroke="white"
                        />
                      </IconButton>
                    </Tooltip>

                    {isSubMenuOpen[eveningReservations?._id] && (
                      <div className="absolute right-12 top-12 w-56 rounded-md shadow-lg shadow-black bg-white ring-1 ring-black ring-opacity-5 z-10 ">
                        <div className="py-1">
                          {/* Options du sous-menu */}
                          {eveningReservations?.isValidated ? null : (
                            <button
                              onClick={() =>
                                handleAcceptReservation(
                                  eveningReservations?._id
                                )
                              }
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left border-b-2 border-gray-100"
                            >
                              ✅ Confirmer la réservation
                            </button>
                          )}
                          <button
                            onClick={() =>
                              cancelReservation(
                                eveningReservations?.reservationId
                              )
                            }
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left border-b-2 border-gray-100"
                          >
                            ⛔ Annuler la réservation
                          </button>
                          <button
                            onClick={() =>
                              toggleSubMenu(eveningReservations?._id)
                            }
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                          >
                            Ne rien faire pour le moment
                          </button>
                        </div>
                      </div>
                    )}
                    <Tooltip content="Voir la réservation">
                      <IconButton
                        variant="text"
                        className="bg-[#00073d]"
                        onClick={() =>
                          toggleEveningModal(eveningReservations?._id)
                        }
                      >
                        <EyeIcon className="h-4 w-4" stroke="white" />
                      </IconButton>
                    </Tooltip>
                    {isModalOpen[eveningReservations?._id] && (
                      <Modal
                        isOpen={isModalOpen[eveningReservations?._id]}
                        onClose={() =>
                          toggleEveningModal(eveningReservations?._id)
                        }
                      >
                        <ReservationDetail
                          reservationDetail={eveningReservations}
                        />
                      </Modal>
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="text-red-300">Aucune réservation le soir / nuit.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Reservation;
