import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Layout from "./Dashboard/Layout";
import Home from "./Pages/Landing";
import Dashboard from "./Dashboard/Dashboard";
import CreateWidget from "./Dashboard/Component/TaxiWidget/CreateWidget";
import IframePage from "./Dashboard/Component/TaxiWidget/Iframe";
import AdminDashboard from "./Dashboard/Component/Admin/AdminDashboard";
import Planning from "./Dashboard/Component/TaxiWidget/Planning";
import Parametres from "./Dashboard/Component/User/Components/Parametres";
import HorairesParams from "./Dashboard/Component/User/Components/HorairesParams";
import ListReservations from "./Dashboard/Component/TaxiWidget/ListReservations";
import Login from "./Component/Log/Login";
import Signup from "./Component/Log/Signup";
import LoginPartenaire from "./Dashboard/Component/User/LoginPartenaire";
import MagicLogin from "./Component/Log/MagicLogin";
import FicheClients from "./Dashboard/Component/TaxiWidget/FicheClients";
import PaiementConfig from "./Dashboard/Component/User/Components/PaiementConfig";
import MesPaiements from "./Dashboard/Component/TaxiWidget/MesPaiements";

function AdminRoute({ element }) {
  const user = useSelector((state) => state.userReducer);
  if (user?.user?.role !== "admin") {
    return <Navigate to="/" />;
  }
  return element;
}

function App() {
  const user = useSelector((state) => state.userReducer);
  const partenaire = useSelector((state) => state.partenaireReducer);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          element={
            <Layout
              user={user}
              partenaireData={user.user || partenaire.partenaire}
            />
          }
        >
          {(user?.user?.email || partenaire?.partenaire?.email) && (
            <>
              <Route
                index
                element={
                  <Dashboard
                    userId={(user.user || partenaire.partenaire)._id}
                    role={(user?.user || partenaire.partenaire)?.role}
                  />
                }
              />
              <Route
                path="/reservations"
                element={
                  <ListReservations
                    userId={(user.user || partenaire.partenaire)._id}
                  />
                }
              />
              <Route
                path="/agenda"
                element={
                  <Planning userId={(user.user || partenaire.partenaire)._id} />
                }
              />
              <Route
                path="/clients"
                element={
                  <FicheClients
                    userId={(user?.user || partenaire.partenaire)._id}
                  />
                }
              />
              <Route
                path="/mes-paiements"
                element={
                  <MesPaiements
                    userId={(user?.user || partenaire.partenaire)._id}
                  />
                }
              />
              <Route
                path="/parametres"
                element={
                  <Parametres user={user?.user || partenaire.partenaire} />
                }
              />
              <Route
                path="/horaires"
                element={
                  <HorairesParams
                    userId={(user.user || partenaire.partenaire)._id}
                  />
                }
              />
              <Route
                path="/paiement-config"
                element={
                  <PaiementConfig
                    userId={(user?.user || partenaire.partenaire)._id}
                  />
                }
              />
            </>
          )}

          {user?.user?.role === "admin" && (
            <>
              <Route
                path="/admin985"
                element={<AdminRoute element={<AdminDashboard />} />}
              />
              <Route
                path="/createUser"
                element={<AdminRoute element={<Signup />} />}
              />
            </>
          )}

          <Route
            path="/createTaxiWidget"
            element={<CreateWidget userId={user.user?._id} />}
          />
        </Route>

        <Route path="/login" element={<Login />} />
        <Route path="/iframePage/:widgetId" element={<IframePage />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/partenaireLogin" element={<LoginPartenaire />} />
        <Route path="/verify/:token" element={<MagicLogin />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
