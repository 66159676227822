import { GET_PARTENAIRE } from "../actions/partenaire.actions";

const initialState = {};

export default function partenaireReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PARTENAIRE:
      return action.payload;
    default:
      return state;
  }
}
