import React, { useRef, useEffect } from "react";
import { ChromePicker } from "react-color";

const ColorPicker = ({
  label,
  color,
  onColorChange,
  isOpen,
  togglePicker,
  placeholder,
}) => {
  const colorPickerRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  const handleClickOutside = (event) => {
    if (
      colorPickerRef.current &&
      !colorPickerRef.current.contains(event.target)
    ) {
      togglePicker();
    }
  };

  return (
    <div className="mb-3" id="color-picker-container" ref={colorPickerRef}>
      <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">
        {label}
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">
        <input
          type="text"
          className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          value={color}
          onClick={togglePicker}
          readOnly
        />
        <div className="absolute inset-y-0 right-0 flex items-center">
          <label htmlFor="currency" className="sr-only">
            Devise
          </label>
          <div
            id="currency"
            name="currency"
            className="flex items-center justify-center rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
          >
            {placeholder}
          </div>
        </div>
        {isOpen && (
          <div className="absolute top-full left-0 z-10">
            <ChromePicker color={color} onChange={onColorChange} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ColorPicker;
