import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUser } from "../../actions/user.actions";
import { toast } from "sonner";
import axios from "axios";

const Checksession = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Actualiser la session toutes les 10 minutes
    const interval = setInterval(() => {
      dispatch(getUser());
    }, 600000); // 10 minutes

    return () => clearInterval(interval);
  }, [dispatch]);

  const timeRemaining = useSelector(
    (state) => state.userReducer?.timeRemaining
  );

  const showWarning = timeRemaining !== undefined && timeRemaining <= 600; // 600 secondes = 10 minutes

  const handleLogout = async () => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/user/logout`,
          {},
          { withCredentials: true }
        )
        .then(() => {
          window.location = "/";
        });
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <>
      {showWarning &&
        toast(
          "Attention, votre session va expirer. Voulez-vous vous reconnecter ?",
          {
            action: {
              label: "Me reconnecter",
              onClick: () => handleLogout(),
            },
            duration: 600000,
          }
        )}
    </>
  );
};

export default Checksession;
