import axios from "axios";

export const GET_USER = "GET_USER";

export const getUser = (uid) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/user/${uid}`,
      {
        withCredentials: true,
      }
    );
    dispatch({ type: GET_USER, payload: res.data });
  } catch (error) {
    console.error(error);
  }
};
