import React from "react";

const Skeleton = ({ form }) => {
  return (
    <>
      {form ? (
        <div className="py-4 rounded shadow-md w-[800px] animate-pulse bg-gray-50">
          <div className="flex p-4 space-x-4 sm:px-8">
            <div className="flex-shrink-0 w-16 h-16 rounded-full bg-gray-300"></div>
            <div className="flex-1 py-2 space-y-4">
              <div className="w-full h-3 rounded bg-gray-300"></div>
              <div className="w-5/6 h-3 rounded bg-gray-300"></div>
            </div>
          </div>
          <div className="p-4 space-y-4 sm:px-8 gap-2">
            <div className="w-full h-4 rounded bg-gray-300"></div>
            <div className="w-full h-4 rounded bg-gray-300"></div>
            <div className="w-3/4 h-4 rounded bg-gray-300"></div>
            <div className="w-full h-4 rounded bg-gray-300"></div>
            <div className="w-full h-4 rounded bg-gray-300"></div>
            <div className="w-3/4 h-4 rounded bg-gray-300"></div>
            <div className="w-full h-4 rounded bg-gray-300"></div>
            <div className="w-full h-4 rounded bg-gray-300"></div>
            <div className="w-3/4 h-4 rounded bg-gray-300"></div>
            <div className="w-full h-4 rounded bg-gray-300"></div>
            <div className="w-full h-4 rounded bg-gray-300"></div>
            <div className="w-3/4 h-4 rounded bg-gray-300"></div>
          </div>
        </div>
      ) : (
        <div
          role="status"
          className="w-full p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700  dark:border-gray-700"
        >
          <div className="flex items-center justify-between gap-8 ">
            <div>
              <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5 text-center"></div>
            </div>
            <div className="w-[90%] h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
          </div>
          <div className="flex items-center justify-between gap-8 ">
            <div>
              <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5 text-center"></div>
            </div>
            <div className="w-[90%] h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
          </div>
          <div className="flex items-center justify-between gap-8 ">
            <div>
              <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5 text-center"></div>
            </div>
            <div className="w-[90%] h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
          </div>
          <div className="flex items-center justify-between gap-8 ">
            <div>
              <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5 text-center"></div>
            </div>
            <div className="w-[90%] h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
          </div>

          <span className="sr-only">Chargement en cours...</span>
        </div>
      )}
    </>
  );
};

export default Skeleton;
