import React, { useState } from "react";
import axios from "axios";
import moment from "moment";
import { toast } from "sonner";
import {
  UserGroupIcon,
  MapPinIcon,
  CurrencyEuroIcon,
  ShoppingBagIcon,
} from "@heroicons/react/24/outline";
import { Select, Option } from "@material-tailwind/react";

const CreateReservations = ({ widgetId, onClose, loadReservationDetails }) => {
  const [reservationData, setReservationData] = useState({
    fullName: "",
    tel: "",
    email: "",
    startAddress: "",
    endAddress: "",
    passengers: "",
    baggage: "",
    departureTime: "",
    arrivedTime: "",
    isRoundTrip: false,
    price: "",
    km: "",
    paiementMethod: "cash",
  });
  const [clients, setClients] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setReservationData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSwitchChange = () => {
    setReservationData((prevState) => ({
      ...prevState,
      isRoundTrip: !prevState.isRoundTrip,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const departureTimeUtc = moment
      .utc(reservationData.departureTime)
      .toISOString();
    const arrivedTimeUtc = moment
      .utc(reservationData.arrivedTime)
      .toISOString();

    const requestData = {
      ...reservationData,
      departureTime: departureTimeUtc,
      arrivedTime: arrivedTimeUtc,
    };

    try {
      const vacationModeCheck = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/widgets/taxi/widget/${widgetId}/vacation-mode`
      );

      if (vacationModeCheck?.data?.vacations[0]) {
        const { vacations } = vacationModeCheck?.data;

        const isDuringVacation = vacations?.some((vacation) => {
          return (
            moment(requestData.departureTime).isBetween(
              vacation?.startDate,
              vacation?.endDate
            ) ||
            moment(requestData?.arrivedTime)?.isBetween(
              vacation?.startDate,
              vacation?.endDate
            )
          );
        });

        if (isDuringVacation) {
          toast.error(
            "Impossible de créer une réservation pendant les vacances"
          );
          return;
        }
      }

      const clientResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/widgets/taxi/widget/${widgetId}/client/search`,
        {
          params: {
            clientEmail: reservationData.email,
            clientTel: reservationData.tel,
          },
        }
      );

      let clientId;
      let reservationId;

      // Si le client existe déjà, récupérer son ID
      if (clientResponse.data.clients.length > 0) {
        clientId = clientResponse.data.clients[0]._id;

        // Créez la réservation et récupérez son ID
        const reservationResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/widgets/taxi/reservations/${widgetId}`,
          {
            reservation: { ...requestData, clientId: clientId },
          }
        );

        reservationId = reservationResponse.data.reservationId; // Stockez l'ID de la réservation
      } else {
        // Si le client n'existe pas, le créer et créer la réservation
        const newClientResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/widgets/taxi/client/widget/${widgetId}`,
          {
            fullName: requestData.fullName,
            email: requestData.email,
            tel: requestData.tel,
          }
        );

        console.log(newClientResponse);
        clientId = newClientResponse.data.clients[0]._id;
        reservationId = newClientResponse.data.clients[0].reservations[0]._id;
      }

      // Utilisez l'ID de la réservation pour créer la réservation côté client
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/widgets/taxi/client/widget/${widgetId}/client/${clientId}`,
        {
          reservation: { ...reservationData, reservationId: reservationId },
        }
      );

      toast.success("Réservation créée avec succès");

      loadReservationDetails();
      onClose();
    } catch (error) {
      toast.error("Erreur lors de la création de la réservation");
    }
  };

  const handleSearchClient = async () => {
    try {
      const clients = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/widgets/taxi/widget/${widgetId}/clients`
      );
      setClients(clients.data.clients);
    } catch (error) {
      toast.error("Erreur lors de la récupération des clients");
    }
  };

  const handleChangeClient = (clientId) => {
    const selectedClient = clients.find((client) => client._id === clientId);
    if (selectedClient) {
      setReservationData((prevState) => ({
        ...prevState,
        fullName: selectedClient.fullName,
        tel: selectedClient.tel,
        email: selectedClient.email,
      }));
    }
  };

  return (
    <section className="relative overflow-hidden p-3">
      <div className="mx-auto">
        <div className="mx-auto text-center">
          <p className="font-display text-3xl tracking-tight text-slate-800 sm:text-4xl">
            Créer une réservation
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="flex justify-between gap-4 mt-4">
            <div className="w-1/2 pr-4 rounded-2xl border border-gray-200 p-8">
              <h3 className="font-bold text-lg text-gray-900 flex items-center">
                <span className="mr-3">
                  <UserGroupIcon className="h-6 w-6" />
                </span>
                Fiche client
              </h3>
              <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-3">
                <label htmlFor="fullName">Nom complet :</label>
                <input
                  type="text"
                  name="fullName"
                  className="p-1 border px-6 border-gray-200 rounded-xl w-[330px]"
                  value={reservationData.fullName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-3">
                <label htmlFor="tel">Téléphone :</label>
                <input
                  type="tel"
                  name="tel"
                  className="p-1 border px-6 border-gray-200 rounded-xl w-[330px]"
                  value={reservationData.tel}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-3">
                <label htmlFor="email">Email :</label>
                <input
                  type="email"
                  name="email"
                  className="p-1 border px-6 border-gray-200 rounded-xl w-[330px]"
                  value={reservationData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              {/* Rechercher une fiche client exisatnte */}
              <h3 className="font-bold text-lg text-gray-900 flex items-center mt-8">
                <span className="mr-3">
                  <UserGroupIcon className="h-6 w-6" />
                </span>
                OU
              </h3>
              <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-3">
                <label htmlFor="searchClient">
                  Rechercher un client existant :
                </label>
                <div className="flex gap-2 items-center">
                  <Select
                    name="searchClient"
                    onClick={handleSearchClient}
                    onChange={(clientId) => handleChangeClient(clientId)}
                    label="Mes clients"
                    className="flex w-72 flex-col gap-6"
                    required
                  >
                    {clients.map((client) => (
                      <Option key={client._id} value={client._id}>
                        {client.fullName || "Vide"}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            {/* Trajet */}
            <div className="w-1/2 pl-4 rounded-2xl border border-gray-200 p-8">
              <div className="flex justify-between items-center">
                <h3 className="font-bold text-lg text-gray-900 flex items-center">
                  <span className="mr-3">
                    <MapPinIcon className="h-6 w-6" />
                  </span>
                  Trajet
                </h3>
                <div className="flex items-center">
                  <p className="font-bold mr-3">Aller-retour ?</p>
                  <input
                    type="checkbox"
                    id={`toggleSwitch`}
                    checked={reservationData.isRoundTrip}
                    onChange={() => handleSwitchChange()}
                    className="hidden"
                  />
                  <label
                    htmlFor={`toggleSwitch`}
                    className={`relative w-12 h-6 rounded-full cursor-pointer transition-colors duration-200 ease-in-out ${
                      reservationData.isRoundTrip
                        ? "bg_blue_local"
                        : "bg-gray-300"
                    }`}
                  >
                    <span
                      className={`absolute left-1 top-1 w-4 h-4 rounded-full bg-white shadow-md transition-transform duration-200 ease-in-out transform ${
                        reservationData.isRoundTrip
                          ? "translate-x-6"
                          : "translate-x-0"
                      }`}
                    ></span>
                  </label>
                </div>
              </div>

              <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-3">
                <label htmlFor="startAdress">Adresse de départ :</label>
                <input
                  type="text"
                  name="startAddress"
                  className="p-1 border px-6 border-gray-200 rounded-xl w-[330px]"
                  value={reservationData.startAddress}
                  onChange={handleChange}
                  autoComplete="nope"
                  required
                />
              </div>
              <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-3">
                <label htmlFor="endAdress">Adresse de destination :</label>
                <input
                  type="text"
                  name="endAddress"
                  className="p-1 border px-6 border-gray-200 rounded-xl w-[330px]"
                  value={reservationData.endAddress}
                  onChange={handleChange}
                  autoComplete="nope"
                  required
                />
              </div>
              <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-3">
                <label htmlFor="departureTime">Heure de départ :</label>
                <input
                  type="datetime-local"
                  name="departureTime"
                  className="p-1 border px-6 border-gray-200 rounded-xl"
                  value={reservationData.departureTime}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-3">
                <label htmlFor="arrivedTime">Heure d'arrivée :</label>
                <input
                  type="datetime-local"
                  name="arrivedTime"
                  className="p-1 border px-6 border-gray-200 rounded-xl"
                  value={reservationData.arrivedTime}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="flex gap-2">
                <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-3 gap-4 w-full">
                  <label htmlFor="baggage">Bagages :</label>
                  <div className="flex gap-2 items-center">
                    <ShoppingBagIcon className="h-5 w-5" />
                    <input
                      type="number"
                      name="baggage"
                      className="p-1 border px-6 border-gray-200 rounded-xl w-[100px]"
                      value={reservationData.baggage}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-3 gap-4 w-full">
                  <label htmlFor="passengers">Passagers :</label>
                  <div className="flex gap-2 items-center">
                    <UserGroupIcon className="h-5 w-5" />
                    <input
                      type="number"
                      name="passengers"
                      className="p-1 border px-6 border-gray-200 rounded-xl w-[100px]"
                      value={reservationData.passengers}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="flex gap-2">
                <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-3 gap-4 w-full">
                  <label htmlFor="km">Kilomètres :</label>
                  <div className="flex gap-2 items-center">
                    <MapPinIcon className="h-5 w-5" />
                    <input
                      type="number"
                      name="km"
                      className="p-1 border px-6 border-gray-200 rounded-xl w-[100px]"
                      value={reservationData.km}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-3 gap-4 w-full">
                  <label htmlFor="price">Prix :</label>
                  <div className="flex gap-2 items-center">
                    <CurrencyEuroIcon className="h-5 w-5" />
                    <input
                      type="number"
                      name="price"
                      className="p-1 border px-6 border-gray-200 rounded-xl w-[100px]"
                      value={reservationData.price}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center border rounded-xl mt-3 p-3">
            <button
              type="submit"
              className="inline-block w-auto min-w-[250px] max-h-[40px] py-2 text-white transition-all rounded-md shadow-lg sm:w-auto bg-[#00073d] shadow-indigo-200 hover:shadow-lg hover:shadow-indigo-400 hover:-translate-y-px "
            >
              Créer une réservation
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default CreateReservations;
