import React, { useState } from "react";
import classNames from "classnames";
import EditWidget from "./EditWidget";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";

const RightModal = ({ image, editMode, tutoMode, widgetId, onClose }) => {
  const [isVisible, setIsVisible] = useState(editMode);

  const toggleTutorial = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className="fixed top-0 right-0 z-20 w-2/3">
      {tutoMode && (
        <button
          className="bg-black text-white px-4 py-2 rounded-md shadow fixed top-[50%] w-[300px] right-0 rotate-90 transform origin-top-right"
          onClick={toggleTutorial}
        >
          Voir le tuto
        </button>
      )}

      {isVisible && (
        <div
          className={classNames(
            "transition-all duration-500",
            { "opacity-100 translate-x-0 ": isVisible },
            { "opacity-0 translate-x-full": !isVisible }
          )}
          style={{
            minWidth: "100%",
            minHeight: "100vh",
            background: "#fcfbfb",
            boxShadow: "0 0 10px black",
          }}
        >
          <div className="flex items-center justify-center content-center h-dvh relative">
            {image && <img src={image} alt="tutoriel" className="w-[650px]" />}

            {editMode && <EditWidget widgetId={widgetId} />}
            <button
              className="absolute top-4 left-4 text-black text-xl"
              onClick={tutoMode ? toggleTutorial : onClose}
            >
              <FontAwesomeIcon icon={faArrowAltCircleRight} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RightModal;
