import React, { useState, useMemo } from "react";
import MenuParams from "./MenuParams";
import axios from "axios";
import { toast } from "sonner";

const Parametres = ({ user }) => {
  const [userInfo, setUserInfo] = useState({
    email: user.email,
    fullName: user.fullName,
    tel: user.tel,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const updateUrl = () => {
    if (user.role === "partenaire") {
      return `updatePartenaire/${user._id}`;
    } else {
      return `updateUser/${user._id}`;
    }
  };

  const url = updateUrl();

  const handleSubmit = async (e) => {
    e.preventDefault();

    await axios
      .put(`${process.env.REACT_APP_API_URL}/api/user/${url}`, userInfo)
      .then(() => {
        toast.success("Vos informations ont été mises à jour avec succès");
      })
      .catch(() => {
        toast.error("Erreur lors de la mise à jour de vos informations");
      });
  };

  const memoizedForm = useMemo(
    () => (
      <section className="relative overflow-hidden p-3">
        <div className="mx-auto">
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col justify-between gap-4 mt-4">
              <div className="flex-col flex">
                <label htmlFor="fullName">Nom complet :</label>
                <input
                  type="text"
                  name="fullName"
                  className="p-1 border px-6 border-gray-400 rounded-xl w-[600px]"
                  value={userInfo?.fullName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="flex-col flex">
                <label htmlFor="tel">Téléphone :</label>
                <input
                  type="tel"
                  name="tel"
                  className="p-1 border px-6 border-gray-400 rounded-xl w-[600px]"
                  value={userInfo?.tel}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="flex-col flex">
                <label htmlFor="email">Email :</label>
                <input
                  type="email"
                  name="email"
                  autoComplete="none"
                  className="p-1 border px-6 border-gray-400 rounded-xl w-[600px]"
                  value={userInfo?.email}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="flex justify-center items-center border rounded-xl mt-6 p-3">
              <button
                type="submit"
                className="inline-block w-auto min-w-[250px] max-h-[40px] py-2 text-white transition-all rounded-md shadow-lg sm:w-auto bg-[#00073d] shadow-indigo-200 hover:shadow-lg hover:shadow-indigo-400 hover:-translate-y-px "
              >
                Enregister
              </button>
            </div>
          </form>
        </div>
      </section>
    ),
    [userInfo]
  );

  return (
    <div className="flex-col p-2 lg:p-4 w-full">
      <div className="flex gap-4 justify-between ">
        <h1 className="title_local">Paramètres Horaires</h1>
      </div>
      <div className="p-2 lg:p-4 flex space-x-0 space-y-4 w-full gap-4">
        <div className="mt-3 border rounded-xl max-w-[270px] w-full">
          <MenuParams />
        </div>
        <div className="flex-2 border rounded-xl w-full">{memoizedForm}</div>
      </div>
    </div>
  );
};

export default Parametres;
