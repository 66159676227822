import React from "react";
import { useParams } from "react-router-dom";
import SimulateurTaxi from "./SimulateurTaxi";

const IframePage = () => {
  const { widgetId } = useParams();

  return <SimulateurTaxi widgetId={widgetId} />;
};

export default IframePage;
