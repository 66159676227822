import React from "react";
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import {
  PresentationChartBarIcon,
  UserPlusIcon,
  PowerIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid";
import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import axios from "axios";
import LOGO from "../asset/logo.png";

export default function Menu({ user, partenaire }) {
  const [open, setOpen] = React.useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const handleLogout = async () => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/user/logout`,
          {},
          { withCredentials: true }
        )
        .then(() => {
          window.location = "/";
        });
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <Card className="relative flex flex-col bg-clip-text-gray-700 h-dvh w-full max-w-[17rem] p-4 shadow-xl shadow-blue-gray-900/5 bg-[#181B34] rounded-none">
      <div className="mb-2 p-4">
        <Typography variant="h3" color="white">
          <div className="mb-4 overflow-hidden truncate w-full">
            <img src={LOGO} alt="logo" />
          </div>
          <p className="text-center text-sm text-white">
            Bienvenue {user?.user?.fullName || partenaire?.fullName}
          </p>
          <p className="text-center text-sm text-white mt-2 italic">
            {user?.user?.role === "admin" ? "Administrateur" : null}
            {partenaire?.role === "partenaire" ? "Partenaire" : null}
          </p>
        </Typography>
      </div>
      <List>
        <Accordion
          open={open === 1}
          stroke="white"
          icon={
            <ChevronDownIcon
              strokeWidth={2.5}
              stroke="white"
              className={`mx-auto h-4 w-4 transition-transform ${
                open === 1 ? "rotate-180" : ""
              }`}
            />
          }
        >
          <ListItem className="p-0 hover:bg-opacity-20" selected={open === 1}>
            <AccordionHeader
              onClick={() => handleOpen(1)}
              className="border-b-0 p-3"
            >
              <ListItemPrefix className="bg-[#e5007e] p-1 border rounded-lg border-[#E5007E]">
                <PresentationChartBarIcon
                  className="h-5 w-5"
                  stroke="none"
                  fill="white"
                />
              </ListItemPrefix>
              <Typography
                color="blue-gray"
                className="mr-auto font-normal text-white hover:bg-opacity-20"
              >
                Dashboard
              </Typography>
            </AccordionHeader>
          </ListItem>
          <AccordionBody className="py-1">
            <List className="p-0">
              <Link to="/">
                <ListItem className="hover:bg-opacity-20">
                  <ListItemPrefix>
                    <ChevronRightIcon
                      strokeWidth={3}
                      className="h-3 w-5"
                      stroke="none"
                      fill="white"
                    />
                  </ListItemPrefix>
                  <p className="text-white">Mes widgets</p>
                </ListItem>
              </Link>
              <Link to="/reservations">
                <ListItem className="hover:bg-opacity-20">
                  <ListItemPrefix>
                    <ChevronRightIcon
                      strokeWidth={3}
                      className="h-3 w-5"
                      stroke="none"
                      fill="white"
                    />
                  </ListItemPrefix>
                  <p className="text-white">Mes Reservations</p>
                </ListItem>
              </Link>
              <Link to="/agenda">
                <ListItem className="hover:bg-opacity-20">
                  <ListItemPrefix>
                    <ChevronRightIcon
                      strokeWidth={3}
                      className="h-3 w-5"
                      stroke="none"
                      fill="white"
                    />
                  </ListItemPrefix>
                  <p className="text-white">Mon Agenda</p>
                </ListItem>
              </Link>
              <Link to="/clients">
                <ListItem className="hover:bg-opacity-20">
                  <ListItemPrefix>
                    <ChevronRightIcon
                      strokeWidth={3}
                      className="h-3 w-5"
                      stroke="none"
                      fill="white"
                    />
                  </ListItemPrefix>
                  <p className="text-white">Mes clients</p>
                </ListItem>
              </Link>
              <Link to="/mes-paiements">
                <ListItem className="hover:bg-opacity-20">
                  <ListItemPrefix>
                    <ChevronRightIcon
                      strokeWidth={3}
                      className="h-3 w-5"
                      stroke="none"
                      fill="white"
                    />
                  </ListItemPrefix>
                  <p className="text-white">Mes paiements</p>
                </ListItem>
              </Link>
              <Link to="/parametres">
                <ListItem className="hover:bg-opacity-20">
                  <ListItemPrefix>
                    <ChevronRightIcon
                      strokeWidth={3}
                      className="h-3 w-5"
                      stroke="none"
                      fill="white"
                    />
                  </ListItemPrefix>
                  <p className="text-white">Mes Paramètres</p>
                </ListItem>
              </Link>
            </List>
          </AccordionBody>
        </Accordion>
        <hr className="my-2 border-blue-gray-50" />
        {user?.user?.role === "admin" ? (
          <Link to={`/admin985`}>
            <ListItem className="hover:bg-opacity-20">
              <ListItemPrefix className="bg-[#e5007e] p-1 border rounded-lg border-[#E5007E]">
                <UserPlusIcon className="h-5 w-5" stroke="none" fill="white" />
              </ListItemPrefix>
              <p className="text-white">Administration</p>
            </ListItem>
          </Link>
        ) : null}
        <ListItem className="hover:bg-opacity-20">
          <ListItemPrefix className="bg-[#e5007e] p-1 border rounded-lg border-[#E5007E]">
            <ExclamationTriangleIcon
              className="h-5 w-5"
              stroke="none"
              fill="white"
            />
          </ListItemPrefix>
          <a href="mailto:support-prod@local.fr" className="text-white">
            Signaler un bug
          </a>
        </ListItem>
        <ListItem onClick={handleLogout} className="hover:bg-opacity-20">
          <ListItemPrefix className="bg-[#e5007e] p-1 border rounded-lg border-[#E5007E]">
            <PowerIcon className="h-5 w-5" stroke="none" fill="white" />
          </ListItemPrefix>
          <p className="text-white">Déconnexion</p>
        </ListItem>
      </List>
      <p className="text-center text-xs italic text-white absolute bottom-0 left-[30%] right-[30%]">
        V1 prod
      </p>
    </Card>
  );
}
