import React from "react";
import { Link } from "react-router-dom";
import { Card, List, ListItem, ListItemPrefix } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";

const MenuParams = () => {
  return (
    <Card className="relative flex flex-col bg-clip-text-gray-700 w-full p-2 shadow-xl shadow-blue-gray-900/5 h-full">
      <List>
        <Link to={"/parametres"}>
          <ListItem className="hover:bg-opacity-50 bg-blue-gray-20">
            <ListItemPrefix className="bg-[#e5007e] p-1 border rounded-lg border-[#E5007E]">
              <FontAwesomeIcon
                icon={faGear}
                className="h-3 w-3 custom-white-icon"
              />
            </ListItemPrefix>
            <p>Paramètres généraux</p>
          </ListItem>
        </Link>
        <Link to={"/horaires"}>
          <ListItem className="hover:bg-opacity-50 bg-blue-gray-20">
            <ListItemPrefix className="bg-[#e5007e] p-1 border rounded-lg border-[#E5007E]">
              <FontAwesomeIcon
                icon={faGear}
                className="h-3 w-3 custom-white-icon"
              />
            </ListItemPrefix>
            <p>Horaires et fermetures</p>
          </ListItem>
        </Link>
        <Link to={"/paiement-config"}>
          <ListItem className="hover:bg-opacity-50 bg-blue-gray-20">
            <ListItemPrefix className="bg-[#e5007e] p-1 border rounded-lg border-[#E5007E]">
              <FontAwesomeIcon
                icon={faGear}
                className="h-3 w-3 custom-white-icon"
              />
            </ListItemPrefix>
            <p>Moyen de paiement</p>
          </ListItem>
        </Link>
      </List>
    </Card>
  );
};

export default MenuParams;
