import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Stepper, Step, Typography } from "@material-tailwind/react";
import { UserIcon, BuildingLibraryIcon } from "@heroicons/react/24/outline";

const MagicLogin = () => {
  const [loading, setLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [verificationStatus, setVerificationStatus] = useState("");
  const [redirect, setRedirect] = useState(false);

  const { token } = useParams();

  useEffect(() => {
    verifyToken();
  }, [token]);

  const verifyToken = async () => {
    try {
      setTimeout(() => {
        setLoading(false);
        handleNext();
      }, 5000);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/user/verify/${token}`,
        { withCredentials: true }
      );

      if (response.status === 200) {
        setVerificationStatus("success");
      } else if (response.status === 401) {
        setVerificationStatus("expired");
      }
    } catch (error) {
      setVerificationStatus("error");
    }
  };

  const handleNext = () => {
    setActiveStep((cur) => cur + 1);
  };

  useEffect(() => {
    if (verificationStatus === "success") {
      setTimeout(() => {
        setRedirect(true);
      }, 3000);
      setTimeout(() => {
        if (redirect) {
          window.location = "/";
        }
      }, 5000);
    }
  }, [verificationStatus, redirect]);

  return (
    <div className="w-full px-24 py-4 flex items-center justify-center h-dvh">
      <div className="w-[25rem]">
        <Stepper activeStep={activeStep}>
          <Step>
            <UserIcon className="h-5 w-5 custom-white-icon" />
            <div className="absolute -bottom-[4.5rem] w-max text-center">
              <Typography
                variant="h6"
                color={activeStep === 0 ? "blue-gray" : "gray"}
              >
                Connexion
              </Typography>
              <Typography
                color={activeStep === 0 ? "blue-gray" : "gray"}
                className="font-normal"
              >
                {loading ? (
                  <Typography color="blue-gray" className="font-normal">
                    <p className="text-gray-400">Connexion en cours...</p>
                  </Typography>
                ) : (
                  <Typography color="blue-gray" className="font-normal">
                    {verificationStatus === "success" && (
                      <p className="text-gray-800">
                        Connexion établie avec succès ✅
                      </p>
                    )}
                    {verificationStatus === "expired" && (
                      <p className="text-red-500">Le token a expiré ❌</p>
                    )}
                    {verificationStatus === "error" && (
                      <p className="text-red-500">Une erreur est survenue ❌</p>
                    )}
                  </Typography>
                )}
              </Typography>
            </div>
          </Step>
          <Step>
            <BuildingLibraryIcon className="h-5 w-5 custom-white-icon" />
            <div className="absolute -bottom-[4.5rem] w-max text-center">
              <Typography
                variant="h6"
                color={activeStep === 1 ? "blue-gray" : "gray"}
              >
                Votre interface
              </Typography>
              <Typography
                color={activeStep === 1 ? "blue-gray" : "gray"}
                className="font-normal"
              >
                {redirect ? (
                  <p className="text-gray-800">Redirection en cours...</p>
                ) : (
                  <p className="text-gray-300">Redirection en cours...</p>
                )}
              </Typography>
            </div>
          </Step>
        </Stepper>
      </div>
    </div>
  );
};

export default MagicLogin;
