import axios from "axios";

export const GET_PARTENAIRE = "GET_PARTENAIRE";

export const getPartenaire = (email) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/user/getPartenaire/${email}`,
      {
        withCredentials: true,
      }
    );
    dispatch({ type: GET_PARTENAIRE, payload: res.data });
  } catch (error) {
    console.error(error);
  }
};
