import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "sonner";
import ColorPicker from "../../../Component/ColorPicker/ColorPicker";
import Modal from "../../../Component/Modal/Modal";
import IframeCode from "./IframeCode";
import Tuto from "../../../asset/tuto.png";
import RightModal from "./RightModal";
import axios from "axios";
import {
  UserGroupIcon,
  CurrencyEuroIcon,
  ShoppingBagIcon,
} from "@heroicons/react/24/outline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeDropper } from "@fortawesome/free-solid-svg-icons";

const CreateWidget = () => {
  const user = useSelector((state) => state.userReducer);
  const [isLoading, setIsLoading] = useState(false);
  const [widgetData, setWidgetData] = useState({
    userId: user?.user?._id,
    title: "",
    pricePerKmDay: 0,
    pricePerKmNight: 0,
    pricePerKmSunday: 0,
    pricePerKmRoundTripDay: 0,
    pricePerKmRoundTripSunday: 0,
    isOnline: "online",
    type: "Widget Taxi",
    btnColor: "",
    backgroundColor: "",
    labelColor: "",
  });
  const [activeColorPicker, setActiveColorPicker] = useState(null);
  const [colorPickers, setColorPickers] = useState({
    backgroundColor: false,
    btnColor: false,
    labelColor: false,
  });
  const [widgetId, setWidgetId] = useState("");
  const [formChanged, setFormChanged] = useState(false);

  const handleColorPickerToggle = (fieldName) => {
    setColorPickers((prevPickers) => ({
      ...Object.keys(prevPickers).reduce((acc, key) => {
        acc[key] = key === fieldName ? !prevPickers[key] : false;
        return acc;
      }, {}),
    }));

    setActiveColorPicker(fieldName);
  };

  const handleColorChange = (color) => {
    setWidgetData((prevData) => ({
      ...prevData,
      [activeColorPicker]: color.hex,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setWidgetData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    handleFormChange();
  };

  const handleFormChange = () => {
    setFormChanged(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Vérifier si le partenaire existe déjà
      let partenaireResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/user/createPartenaire`,
        {
          fullName: widgetData.fullName,
          tel: widgetData.tel,
          email: widgetData.email,
        }
      );

      if (partenaireResponse.status === 201) {
        let partenaireId = partenaireResponse.data.partenaireId;
        const partenairePhone = partenaireResponse.data.partenaireTel;
        const partenaireEmail = partenaireResponse.data.partenaireEmail;
        if (!partenaireId) {
          partenaireId = partenaireResponse.data.partenaire._id;
        }
        // Envoi des données de trajet avec l'ID du partenaire

        const widgetResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/widgets/taxi/create`,
          {
            ...widgetData,
            partenaireId: partenaireId,
            contactPhone: partenairePhone,
            contactMail: partenaireEmail,
          }
        );

        if (widgetResponse.status === 201) {
          toast.success("Widget créé avec succès");
          const widgetId = widgetResponse.data.widget._id;
          setWidgetId(widgetId);
        } else {
          toast.error("Erreur lors de la création du widget");
        }
      } else {
        toast.error("Erreur lors de la création du partenaire");
      }
    } catch (error) {
      switch (error.response.status) {
        case 401:
          toast.error("Vous n'êtes pas connecté");
          break;
        case 400:
          toast.error("Attention, un widget existe déjà avec ce nom.");
          break;
        default:
          toast.error("Une erreur est survenue");
          break;
      }
    }

    setIsLoading(false);
  };

  return (
    <div className="p-4 w-full flex gap-10">
      {user?.user?.email ? (
        <div className="w-full">
          <form onSubmit={handleSubmit}>
            <div className="w-full flex flex-col items-center pr-4 rounded-2xl border border-gray-200 p-5">
              <label
                className="block uppercase tracking-wide text-grey-darker text-sm mb-2 text-center font-extrabold"
                htmlFor="grid-password"
              >
                Donner un titre a votre simulateur *
              </label>
              <input
                className="text-center block w-3/4 rounded-md border-0 p-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                id="grid-password"
                type="texte"
                name="title"
                placeholder="Code sage - Entreprise"
                value={widgetData.title}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="flex justify-between gap-4 mt-4">
              <div className="flex flex-col gap-10 w-1/2">
                <div className="pr-4 rounded-2xl border border-gray-200 p-8">
                  <h3 className="font-bold text-lg text-gray-900 flex items-center">
                    <span className="mr-3">
                      <UserGroupIcon className="h-6 w-6" />
                    </span>
                    Fiche partenaire
                  </h3>
                  <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-2">
                    <label htmlFor="fullName">Nom complet :</label>
                    <input
                      type="text"
                      name="fullName"
                      className="p-1 border px-6 border-gray-200 rounded-xl w-[330px]"
                      value={widgetData.fullName}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-2">
                    <label htmlFor="tel">Téléphone :</label>
                    <input
                      type="tel"
                      name="tel"
                      className="p-1 border px-6 border-gray-200 rounded-xl w-[330px]"
                      value={widgetData.tel}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-2">
                    <label htmlFor="email">Email :</label>
                    <input
                      type="email"
                      name="email"
                      className="p-1 border px-6 border-gray-200 rounded-xl w-[330px]"
                      value={widgetData.email}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className=" pr-4 rounded-2xl border border-gray-200 p-8">
                  <h3 className="font-bold text-lg text-gray-900 flex items-center">
                    <span className="mr-3">
                      <UserGroupIcon className="h-6 w-6" />
                    </span>
                    Customisation
                  </h3>
                  <div className="flex gap-3">
                    <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-2 gap-4 w-1/3">
                      <ColorPicker
                        label="Couleur de fond"
                        color={widgetData.backgroundColor}
                        onColorChange={(color) => handleColorChange(color)}
                        isOpen={colorPickers.backgroundColor}
                        togglePicker={() =>
                          handleColorPickerToggle("backgroundColor")
                        }
                        placeholder={<FontAwesomeIcon icon={faEyeDropper} />}
                      />
                    </div>
                    <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-2 gap-4 w-1/3">
                      <ColorPicker
                        label="Couleur des boutons"
                        color={widgetData.btnColor}
                        onColorChange={(color) => handleColorChange(color)}
                        isOpen={colorPickers.btnColor}
                        togglePicker={() => handleColorPickerToggle("btnColor")}
                        placeholder={<FontAwesomeIcon icon={faEyeDropper} />}
                      />
                    </div>
                    <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-2 gap-4 w-1/3">
                      <ColorPicker
                        label="Couleur du texte"
                        color={widgetData.labelColor}
                        onColorChange={(color) => handleColorChange(color)}
                        isOpen={colorPickers.labelColor}
                        togglePicker={() =>
                          handleColorPickerToggle("labelColor")
                        }
                        placeholder={<FontAwesomeIcon icon={faEyeDropper} />}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Trajet */}
              <div className="w-1/2 pl-4 rounded-2xl border border-gray-200 p-8">
                <div className="flex justify-between items-center">
                  <h3 className="font-bold text-lg text-gray-900 flex items-center">
                    <span className="mr-3">
                      <CurrencyEuroIcon className="h-6 w-6" />
                    </span>
                    Prix aller simple
                  </h3>
                </div>

                <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-2">
                  <label htmlFor="pricePerKmDay">Prix / kilomètre*</label>
                  <input
                    type="number"
                    step=".01"
                    min="0"
                    name="pricePerKmDay"
                    id="price"
                    className="p-1 border px-6 border-gray-200 rounded-xl w-[330px]"
                    placeholder="0.00"
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-2">
                  <label htmlFor="pricePerKmNight">
                    Prix / kilomètre de nuit*
                  </label>
                  <input
                    type="number"
                    step=".01"
                    min="0"
                    name="pricePerKmNight"
                    id="price"
                    className="p-1 border px-6 border-gray-200 rounded-xl w-[330px]"
                    placeholder="0.00"
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="flex justify-between items-center mt-3">
                  <h3 className="font-bold text-lg text-gray-900 flex items-center">
                    <span className="mr-3">
                      <CurrencyEuroIcon className="h-6 w-6" />
                    </span>
                    Prix aller retour
                  </h3>
                </div>
                <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-2">
                  <label htmlFor="pricePerKmRoundTripDay">
                    Prix / kilomètre*
                  </label>
                  <input
                    type="number"
                    step=".01"
                    min="0"
                    name="pricePerKmRoundTripDay"
                    id="price"
                    className="p-1 border px-6 border-gray-200 rounded-xl w-[330px]"
                    placeholder="0.00"
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-2">
                  <label htmlFor="pricePerKmRoundTripSunday">
                    Prix / kilomètre de nuit*
                  </label>
                  <input
                    type="number"
                    step=".01"
                    min="0"
                    name="pricePerKmRoundTripSunday"
                    id="price"
                    className="p-1 border px-6 border-gray-200 rounded-xl w-[330px]"
                    placeholder="0.00"
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="flex justify-between items-center mt-3">
                  <h3 className="font-bold text-lg text-gray-900 flex items-center">
                    <span className="mr-3">
                      <CurrencyEuroIcon className="h-6 w-6" />
                    </span>
                    Tarif prise en charge
                  </h3>
                </div>
                <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-2">
                  <label htmlFor="pricePriseEnCharge">
                    Tarif de prise en charge :
                  </label>
                  <input
                    type="number"
                    step=".01"
                    min="0"
                    name="pricePriseEnCharge"
                    id="price"
                    className="p-1 border px-6 border-gray-200 rounded-xl w-[330px]"
                    placeholder="0.00"
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="flex justify-between items-center mt-3">
                  <h3 className="font-bold text-lg text-gray-900 flex items-center">
                    <span className="mr-3">
                      <CurrencyEuroIcon className="h-6 w-6" />
                    </span>
                    Options supplémentaires
                  </h3>
                </div>
                <div className="flex gap-2">
                  <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-2 gap-4 w-full">
                    <label htmlFor="pricePerBaggage">Prix par baggage* :</label>
                    <div className="flex gap-2 items-center">
                      <ShoppingBagIcon className="h-5 w-5" />
                      <input
                        type="number"
                        step=".01"
                        min="0"
                        name="pricePerBaggage"
                        id="price"
                        className="p-1 border px-6 border-gray-200 rounded-xl w-[95px]"
                        placeholder="0.00"
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="flex justify-between items-center bg-gray-100 border rounded-xl mt-3 p-2 gap-4 w-full">
                    <label htmlFor="pricePerPassenger">
                      Prix par passager*
                    </label>
                    <div className="flex gap-2 items-center">
                      <UserGroupIcon className="h-5 w-5" />
                      <input
                        type="number"
                        step=".01"
                        min="0"
                        name="pricePerPassenger"
                        id="price"
                        className="p-1 border px-6 border-gray-200 rounded-xl w-[95px]"
                        placeholder="0.00"
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center border rounded-xl mt-3 p-2">
              <button
                type="submit"
                className="inline-block w-auto min-w-[250px] max-h-[40px] py-2 text-white transition-all rounded-md shadow-lg sm:w-auto bg-[#00073d] shadow-indigo-200 hover:shadow-lg hover:shadow-indigo-400 hover:-translate-y-px "
              >
                {isLoading ? "Création en cours..." : "Créer le widget"}
              </button>
            </div>
          </form>

          <div className="tutoriel flex w-full mt-10 justify-center">
            <RightModal image={Tuto} tutoMode={true} />
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center w-full">
          <p>
            ⛔ Vous n'êtes pas authorisé, veuillez contacter le support pour
            créer un Simulateur ⛔
          </p>
        </div>
      )}

      {widgetId && (
        <Modal
          title="Code iframe"
          isOpen={true}
          onClose={() => setWidgetId("")}
          children={<IframeCode widgetId={widgetId} />}
        />
      )}
    </div>
  );
};

export default CreateWidget;
