import React from "react";
import { CSVLink } from "react-csv";

const TaxiListThreeMonth = ({ taxisList }) => {
  const headers = [
    { label: "Title", key: "title" },
    { label: "Contact Mail", key: "contactMail" },
    { label: "Contact Phone", key: "contactPhone" },
    { label: "Created At", key: "createdAt" },
  ];

  const taxiListMap = taxisList.map((taxi) => {
    return {
      title: taxi.title,
      contactMail: taxi.contactMail,
      contactPhone: taxi.contactPhone,
      createdAt: new Date(taxi.createdAt).toLocaleDateString(),
    };
  });

  return (
    <div className="flex flex-col">
      <div className="p-2">
        <CSVLink
          data={taxiListMap}
          headers={headers}
          filename={"taxi_list.csv"}
          className="max-w-[240px] py-2 px-4 flex justify-center items-center  bg-gray-600 hover:bg-gray-800 focus:ring-gray-500 focus:ring-offset-gray-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
        >
          <svg
            width="20"
            height="20"
            fill="currentColor"
            className="mr-2"
            viewBox="0 0 1792 1792"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1344 1472q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm256 0q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm128-224v320q0 40-28 68t-68 28h-1472q-40 0-68-28t-28-68v-320q0-40 28-68t68-28h427q21 56 70.5 92t110.5 36h256q61 0 110.5-36t70.5-92h427q40 0 68 28t28 68zm-325-648q-17 40-59 40h-256v448q0 26-19 45t-45 19h-256q-26 0-45-19t-19-45v-448h-256q-42 0-59-40-17-39 14-69l448-448q18-19 45-19t45 19l448 448q31 30 14 69z"></path>
          </svg>
          Télécharger CSV
        </CSVLink>
      </div>
      <div className="flex flex-wrap">
        {taxisList.map((taxi) => (
          <div key={taxi._id} className="flex flex-wrap -mx-2 p-1 w-1/3">
            <div className="p-2 w-full ">
              <div className="bg-gray-200 rounded flex p-2 h-full items-center">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"
                  className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4"
                  viewBox="0 0 24 24"
                >
                  <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                  <path d="M22 4L12 14.01l-3-3"></path>
                </svg>
                <div className="flex flex-col">
                  <span className="font-bold">{taxi.title}</span>
                  <span className="font-medium">{taxi.contactMail}</span>
                  <span className="font-medium">{taxi.contactPhone}</span>
                  <span className="font-medium text-primaryColor">
                    {new Date(taxi.createdAt).toLocaleDateString()} à{" "}
                    {new Date(taxi.createdAt).toLocaleTimeString()}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TaxiListThreeMonth;
