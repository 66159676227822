import React, { useCallback, useEffect, useState } from "react";
import useFetch from "../../../Component/Hooks/useFetch";
import { toast } from "sonner";
import axios from "axios";
import Loader from "../Loader/Loader";
import moment from "moment";
import Lottie from "lottie-react";
import CAR from "../../../asset/car.json";
import { UserIcon } from "@heroicons/react/24/outline";
import Modal from "../../../Component/Modal/Modal";
import CreateClient from "./CreateClient";

const FicheClients = ({ userId }) => {
  const [widgets, setWidgets] = useState([]);
  const [selectedWidgetId, setSelectedWidgetId] = useState("");
  const [clients, setClients] = useState([]);
  const [showInformations, setShowInformations] = useState({});
  const [showModal, setShowModal] = useState(false);

  const { data, loading } = useFetch(
    `${process.env.REACT_APP_API_URL}/api/widgets/taxi/getWidgets/${userId}`
  );

  const handleWidgetChange = useCallback((event) => {
    setSelectedWidgetId(event.target.value);
  }, []);

  const fetchClients = useCallback(async () => {
    if (selectedWidgetId) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/widgets/taxi//widget/${selectedWidgetId}/clients/`
        );
        setClients(response?.data?.clients);
      } catch (error) {
        toast.error("Erreur lors du chargement des réservations");
      }
    }
  }, [selectedWidgetId]);

  const handleSearchClient = useCallback(
    (event) => {
      const searchValue = event.target.value;
      if (searchValue.length > 3) {
        const filteredClients = clients.filter((client) =>
          client.fullName.toLowerCase().includes(searchValue.toLowerCase())
        );
        setClients(filteredClients);
      } else {
        fetchClients();
      }
    },
    [clients]
  );

  const handleCreateClient = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  useEffect(() => {
    if (data) {
      setWidgets(data);
      setSelectedWidgetId(data?.widgets[0]?._id);
    }
  }, [data, userId]);

  useEffect(() => {
    if (selectedWidgetId) {
      fetchClients();
    }
  }, [selectedWidgetId, fetchClients]);

  return (
    <div className="flex-col p-2 lg:p-4 w-full">
      <div className="flex gap-4 justify-between">
        <h1 className="title_local">Mes clients</h1>
        {widgets?.widgets && (
          <select
            onChange={handleWidgetChange}
            value={selectedWidgetId}
            className="border border-gray-200 rounded-md px-2 py-1 min-w-[200px] sm:w-auto max-h-[40px] text-gray-700 transition-all shadow-sm hover:shadow-sm hover:shadow-indigo-400 hover:-translate-y-px"
          >
            {widgets?.widgets?.map((widget) => (
              <option key={widget?._id} value={widget?._id}>
                {widget?.title}
              </option>
            ))}
          </select>
        )}
        <button
          onClick={handleCreateClient}
          className="inline-block w-auto min-w-[250px] py-1 max-h-[40px] text-white transition-all rounded-md shadow-xl sm:w-auto bg-[#00073d] shadow-indigo-200 hover:shadow-2xl hover:shadow-indigo-400 hover:-translate-y-px "
        >
          Créer une fiche client
        </button>
      </div>

      <div className="border p-2 lg:p-4 rounded-xl flex space-x-0 space-y-4  w-full">
        <div style={{ flex: "2" }}>
          {/* Affichage des clients */}

          <div className="flex flex-col gap-4">
            <div className="flex flex-col">
              <div className="flex gap-4 justify-between items-center p-2 border rounded-md bg-[#f5f7f9]">
                <div className="flex gap-4 mx-auto p-5">
                  <input
                    type="text"
                    placeholder="Rechercher un client"
                    onChange={handleSearchClient}
                    className="border border-gray-200 rounded-md p-6 py-1 min-w-[800px] sm:w-auto max-h-[40px] text-gray-700 transition-all shadow-sm hover:shadow-sm hover:shadow-indigo-400 hover:-translate-y-px"
                  />
                  <button className="inline-block w-auto min-w-[250px] py-1 max-h-[40px] text-white transition-all rounded-md shadow-xl sm:w-auto bg-[#00073d] shadow-indigo-200 hover:shadow-2xl hover:shadow-indigo-400 hover:-translate-y-px ">
                    Rechercher
                  </button>
                </div>
              </div>
              <div className="flex mt-10 gap-4">
                <div className="mr-6 mt-3 w-1/6">
                  {/* Stats */}
                  <ul className="flex flex-col gap-y-6 sm:gap-y-8">
                    <li>
                      <figure className="relative rounded-2xl bg-white p-6 text-center shadow-xl shadow-slate-900/10">
                        <blockquote className="relative p-3">
                          <div className="text-6xl font-bold tracking-tight text-slate-900">
                            {loading ? <Loader /> : clients?.length}
                          </div>
                        </blockquote>
                        <figcaption className="text-center">
                          <div className="font-display text-slate-900">
                            ✅ Total de clients
                          </div>
                        </figcaption>
                      </figure>
                    </li>
                  </ul>
                </div>
                <div className="flex flex-col gap-4 w-full mt-5">
                  {loading && <Loader />}
                  {clients?.map((client) => (
                    <div className="flex flex-col" key={client?._id}>
                      <div className="flex gap-4 justify-between items-center p-2 border rounded-md bg-[#f5f7f9]">
                        <div className="flex items-center gap-3">
                          <UserIcon className="h-7 w-7" />
                          <div>
                            <p className="font-bold">{client?.fullName}</p>
                            <p>{client?.tel}</p>
                          </div>
                        </div>
                        <div>
                          <button
                            onClick={() =>
                              setShowInformations((prevState) => ({
                                ...prevState,
                                [client?._id]: !prevState[client?._id] || false,
                              }))
                            }
                            className="text-white bg-[#00073d] rounded-md px-2 py-1"
                          >
                            +
                          </button>
                        </div>
                      </div>
                      {showInformations[client?._id] && (
                        <div className="flex gap-4 p-4 bg-[#f5f7f9]">
                          <div className="w-1/4">
                            <div className="max-w-2xl mx-4 sm:max-w-sm md:max-w-sm lg:max-w-sm xl:max-w-sm sm:mx-auto md:mx-auto lg:mx-auto xl:mx-auto mt-16 bg-white shadow-xl rounded-lg text-gray-900">
                              <div className="rounded-t-lg h-32 overflow-hidden">
                                <img
                                  className="object-cover object-top w-full"
                                  src="https://images.unsplash.com/photo-1518623001395-125242310d0c?q=80&w=2549&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                  alt="Mountain"
                                />
                              </div>
                              <div className="mx-auto w-32 h-32 relative -mt-16 border-4 border-white rounded-full overflow-hidden">
                                <img
                                  className="object-cover object-center h-32"
                                  src="https://cdn-icons-png.flaticon.com/512/6596/6596121.png"
                                  alt="Woman looking front"
                                />
                              </div>
                              <div className="text-center mt-2">
                                <h2 className="font-semibold">
                                  {client?.fullName}
                                </h2>
                                <p className="text-gray-500">{client?.email}</p>
                              </div>

                              <div className="p-4 border-t mx-8 mt-2">
                                <a href={`tel: ${client?.tel}`}>
                                  <button className="w-1/2 block mx-auto rounded-full bg-gray-900 hover:shadow-lg font-semibold text-white px-6 py-2">
                                    Appeler
                                  </button>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="flex-1">
                            <div className="p-4 mt-4">
                              <h3 className="text-2xl font-semibold mb-6">
                                Réservations de {client?.fullName}
                              </h3>
                              <div className="container">
                                {client?.reservations?.map((reservation) => (
                                  <div
                                    key={reservation?._id}
                                    className="flex flex-col md:grid grid-cols-14 text-gray-50"
                                  >
                                    <div className="flex gap-2">
                                      <div className="col-start-2 col-end-4 mr-10 md:mx-auto relative">
                                        <div className="h-full w-6 flex items-center justify-center">
                                          <div className="h-full w-1 bg-[#181B34] pointer-events-none"></div>
                                        </div>
                                        <div className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-[#181B34] shadow text-center">
                                          <i className="fas fa-check-circle text-white"></i>
                                        </div>
                                      </div>
                                      <div className="col-start-4 col-end-12 p-4 rounded-xl my-4 mr-auto flex shadow-md w-full bg-white">
                                        <Lottie
                                          animationData={CAR}
                                          height={50}
                                          width={50}
                                          loop={false}
                                        />
                                        <div className="ml-3 overflow-hidden w-full">
                                          <p className="font-bold">
                                            {moment(
                                              reservation?.departureTime
                                            ).format("DD/MM/YYYY à HH:mm")}
                                          </p>

                                          <div className="text-sm text-gray-500 flex justify-between">
                                            <p>
                                              📍 {reservation?.startAddress}
                                            </p>
                                            <p>📍 {reservation?.endAddress}</p>
                                            <p>
                                              {reservation?.isRoundTrip
                                                ? "Aller-retour"
                                                : "Aller"}{" "}
                                              {reservation?.km} km
                                            </p>
                                            <p className="border border-gray-200 rounded-md bg-green-300 px-4 text-white">
                                              {
                                                reservation?.price
                                                  ?.$numberDecimal
                                              }{" "}
                                              €
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal de création de réservation */}
      {showModal && (
        <Modal
          onClose={handleCloseModal}
          isOpen={showModal}
          children={
            <CreateClient
              widgetId={selectedWidgetId}
              onClose={handleCloseModal}
            />
          }
        />
      )}
    </div>
  );
};

export default FicheClients;
