import React, { useState } from "react";
import axios from "axios";
import { toast } from "sonner";
import LOGO from "../../../asset/LocalVTC.json";
import Lottie from "lottie-react";
import BACKGROUND from "../../../asset/loginPartenaire.jpg";

const LoginPartenaire = () => {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);

  const handleLogin = (event) => {
    event.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/user/loginPartenaire`,
        { email },
        { withCredentials: true }
      )
      .then((response) => {
        if (response.status === 200) {
          setSuccess(true);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 429) {
          // Erreur 429 : Limite de taux atteinte
          toast.error(
            "Vous avez fait trop de tentatives de connexion. Merci de patienter avant de vous reconnecter."
          );
        } else {
          toast.error("Merci de verifier vos identifiants");
        }
      });
  };

  const handleNewLink = () => {
    setSuccess(false);
  };

  return (
    <div
      className="bg-cover bg-center bg-fixed"
      style={{
        backgroundImage: `url(${BACKGROUND})`,
      }}
    >
      <div className="h-screen flex justify-center items-center">
        <div className="bg-white mx-4 p-8 rounded-xl shadow-md w-full md:w-1/2 lg:w-1/3">
          <Lottie
            animationData={LOGO}
            className="mx-auto h-20 w-auto mb-5"
            loop={true}
          />
          <h1 className="text-3xl font-bold mb-8 text-center">
            Connectez-vous à votre espace
          </h1>
          <form className="text-center" onSubmit={handleLogin}>
            <div className="my-10">
              <input
                className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                placeholder="Entrez votre adresse email partenaire"
              />
            </div>

            <div className="my-6">
              {!success ? (
                <button
                  className="bg_blue_local hover:bg-blue-900 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  Recevoir un lien de connexion
                </button>
              ) : (
                <div>
                  <p className="bg-green-100 text-green-900 p-3 border rounded-md font-bold">
                    Un email vous a été envoyé avec un lien de connexion. <br />
                    Merci de vérifier votre boite mail. <br />
                    <br />
                    Si vous ne le trouvez pas, merci de vérifier vos spams.
                  </p>
                  <button
                    className="bg_blue_local text-white p-3 border rounded-md mt-4 hover:bg-blue-900 focus:outline-none focus:shadow-outline"
                    onClick={handleNewLink}
                  >
                    Recevoir un nouveau lien de connexion
                  </button>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPartenaire;
