import React from "react";

function Modal({ isOpen, onClose, children }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-gray-800 opacity-50"></div>
      <div className="bg-gray-50 p-6 rounded-lg shadow-lg z-10 w-2/3 relative ">
        {children}
        <button className="absolute top-1 right-4" onClick={onClose}>
          X
        </button>
      </div>
    </div>
  );
}

export default Modal;
