import React, { useCallback, useEffect, useState } from "react";
import MenuParams from "./MenuParams";
import useFetch from "../../../../Component/Hooks/useFetch";
import axios from "axios";
import Loader from "../../Loader/Loader";
import { toast } from "sonner";

const PaiementConfig = ({ userId }) => {
  const [widgets, setWidgets] = useState([]);
  const [selectedWidgetId, setSelectedWidgetId] = useState("");
  const [paiementMethod, setPaiementMethod] = useState({});

  const { data, loading } = useFetch(
    `${process.env.REACT_APP_API_URL}/api/widgets/taxi/getWidgets/${userId}`
  );

  const handleWidgetChange = useCallback((event) => {
    setSelectedWidgetId(event.target.value);
  }, []);

  const handleToggleSwitch = async (method) => {
    const updatedPaiementMethod = { ...paiementMethod };
    updatedPaiementMethod[method] = !updatedPaiementMethod[method];

    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/widgets/taxi/edite/${selectedWidgetId}/user/${userId}`,
        {
          paiementMethod: updatedPaiementMethod,
        }
      );

      setPaiementMethod(updatedPaiementMethod);
      toast.success("Méthode de paiement mise à jour avec succès");
    } catch (error) {
      console.error("Error updating paiement method:", error);
      toast.error("Erreur lors de la mise à jour de la méthode de paiement");
    }
  };

  useEffect(() => {
    if (data) {
      setWidgets(data);
      setSelectedWidgetId(data?.widgets[0]?._id);
    }
  }, [data, userId]);

  useEffect(() => {
    if (selectedWidgetId) {
      const widget = widgets?.widgets?.find(
        (widget) => widget?._id === selectedWidgetId
      );
      setPaiementMethod(widget?.paiementMethod);
    }
  }, [selectedWidgetId, widgets]);

  return (
    <div className="flex-col p-2 lg:p-4 w-full">
      <div className="flex gap-4 justify-between ">
        <h1 className="title_local">Paramètres Horaires</h1>
        {widgets?.widgets && (
          <select
            onChange={handleWidgetChange}
            value={selectedWidgetId}
            className="border border-gray-200 rounded-md px-2 py-1 min-w-[200px] sm:w-auto max-h-[40px] text-gray-700 transition-all shadow-sm hover:shadow-sm hover:shadow-indigo-400 hover:-translate-y-px"
          >
            {widgets?.widgets?.map((widget) => (
              <option key={widget._id} value={widget._id}>
                {widget.title}
              </option>
            ))}
          </select>
        )}
      </div>
      <div className="p-2 lg:p-4 flex space-x-0 space-y-4 gap-4 w-full">
        <div className="mt-3 border rounded-xl max-w-[270px] w-full">
          {/* Menu */}
          <MenuParams />
        </div>
        <div className="flex-2 flex-col w-full flex gap-4">
          <div className="flex-2 border rounded-xl w-full p-3">
            <p className="font-bold">💳 Configurer un moyen de paiement:</p>
            <div className="flex flex-col gap-3 p-2 border rounded-xl border-gray-200 mt-8">
              {loading && <Loader />}
              {Object.entries(paiementMethod || {}).map(
                ([method, isEnabled]) => (
                  <div
                    key={method}
                    className="flex gap-3 p-2 border rounded-xl border-gray-200"
                  >
                    <input
                      type="checkbox"
                      id={method}
                      className="opacity-0 absolute"
                      checked={isEnabled}
                      onChange={() => handleToggleSwitch(method)}
                    />
                    <label
                      htmlFor={method}
                      className={`relative w-12 h-6 rounded-full cursor-pointer transition-colors duration-200 ease-in-out ${
                        isEnabled ? "bg_blue_local" : "bg-gray-300"
                      }`}
                    >
                      <span
                        className={`absolute left-1 top-1 w-4 h-4 rounded-full bg-white shadow-md transition-transform duration-200 ease-in-out transform ${
                          isEnabled ? "translate-x-6" : "translate-x-0"
                        }`}
                      ></span>
                    </label>
                    <label className="ml-5 mr-4 min-w-[150px] font-bold">
                      {method === "card" ? "Stripe" : "Paiement au chauffeur"}
                    </label>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaiementConfig;
