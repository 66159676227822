import React from "react";
import Login from "../Component/Log/Login";

export default function Home() {
  return (
    <main className="bg-gray-100 h-screen">
      <Login />
    </main>
  );
}
